export enum LANDINGPAGE {
  redirect = "redirect",
  new_signup = "new_signup",
  new_service = "new_service",
  change_plan = "change_plan",
  trial_ending = "trial_ending",
  trial_ended = "trial_ended",
}


export enum USERTYPE {
    member = "member",
    admin = "admin",
}