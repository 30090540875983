import * as React from "react";
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useStoreActions, useStoreState } from "../../hooks";

import { generatePlainText, generateHash } from "../../services/crypto";
import { setUserToken } from "../../services/auth";
import { FaSpinner } from "react-icons/fa";
import { LANDINGPAGE } from "../../enum/action";

const { useEffect } = React;

export default function WPHubRedirect() {
  const history = useHistory();

  const url = new URL(window.location.href).toString();

  const redirectCondition = url.split("/")[url.split("/").length - 1];

  console.log("red con ", redirectCondition);

  const resolved = redirectCondition.split("-");

  const product = resolved[0];
  const withMentoring = resolved[1];
  const freq = resolved[2];

  const calcPrice = (
    withMentoring: boolean,
    isMonthly: boolean,
    key: string
  ): number => {
    if (isMonthly) {
      switch (key) {
        case "qip":
          return withMentoring ? 197 : 97;
        case "portal":
          return withMentoring ? 197 : 97;
        case "qipportal":
          return withMentoring ? 294 : 194;
        default:
          return 997;
      }
    } else {
      switch (key) {
        case "qip":
          return withMentoring ? 1997 : 997;
        case "portal":
          return withMentoring ? 1997 : 997;
        case "qipportal":
          return withMentoring ? 2994 : 1994;
        default:
          return 9970;
      }
    }
  };

  const initDetails = async () => {
    localStorage.clear();

    if (
      resolved &&
      ["qip", "qipportal", "portal"].some((p) => p === product) &&
      ["0", "1"].some((m) => m === withMentoring) &&
      ["monthly", "yearly"].some((m) => m === freq)
    ) {
      history.push(`/payment/sign-up`, {
        action: LANDINGPAGE.new_signup,
        price: calcPrice(withMentoring === "1", freq === "monthly", product),
        product,
        mentoring: withMentoring,
        freq,
      });
    } else {
      history.push(`/`);
    }
  };

  useEffect(() => {
    initDetails();
  }, []);

  return (
    <div
      className={`w-full h-screen flex flex-col justify-center items-center`}
    >
      <FaSpinner className={`animate-spin text-qipGreen font-bold text-6xl`} />

      <div>Please wait...</div>
    </div>
  );
}
