import axios from "../services/axios";
// import axios from "axios";
import { PaymentMethod } from "../types/paymentMethod";

// TODO: use HUB api

const API_URL = process.env.REACT_APP_QIP_API_URL;

export async function get(userId?: number): Promise<PaymentMethod[]> {
  if (!userId) return [];

  let token: any = localStorage.getItem("hubAppToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const { data: response } = await axios.get(
    `${API_URL}/api/stripe/paymentmethod/${userId}`,
    config
  );

  return response?.data || [];
}

export async function add(userId: number, pmId: string) {
  const payload = {
    user_id: userId,
    payment_method_id: pmId,
  };
  let token: any = localStorage.getItem("hubAppToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.put(
    `${API_URL}/api/stripe/paymentmethod`,
    payload,
    config
  );

  return response.status === 200;
}

export async function remove(pmId: string): Promise<boolean> {
  const payload = {
    payment_method_id: pmId,
  };

  let token: any = localStorage.getItem("hubAppToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.post(
    `${API_URL}/api/stripe/paymentmethod/detach`,
    payload,
    config
  );
  return response.status === 200;
}

export const paymentMethodApi = {
  get,
  add,
  remove,
};
