import React, { useEffect, Suspense } from "react";
import {
  BrowserRouter,
  Switch,
  useRouteMatch,
  Route,
  Redirect,
} from "react-router-dom";
import Layout from "../../components/Layout";
import { AppContext, Routes } from "../../context/App";
import Login from "../../pages/LoginPage";

import ForgotPasswordPageRoute from "../ForgotPasswordPage";
import LoginPageRoute from "../LoginPage";
import NewPasswordPageRoute from "../NewPasswordPage";
import PaymentRoute from "../PaymentPage";

const LoggedOutPages: React.FC<any> = (): JSX.Element => {
  const match = useRouteMatch();
  return (
    <BrowserRouter>
      <AppContext.Provider value={{ route: Routes.HOMEPAGE, authed: false }}>
        <Switch>
          <LoginPageRoute
            path={`/login`}
            exact={true}
            route={Routes.LOGINPAGE}
          />

          <ForgotPasswordPageRoute
            path={`/forgot-password`}
            exact={true}
            route={Routes.FORGOTPASSWORDPAGE}
          />

          <NewPasswordPageRoute
            path={`/new-password/:token`}
            exact={true}
            route={Routes.NEWPASSWORDPAGE}
          />

          <PaymentRoute
            path={`/payment/:page(sign-up)`}
            exact={false}
            route={Routes.PAYMENT}
          />

          <Route
            exact
            path={`/`}
            render={(props) => {
              return (
                <Layout route={Routes.LOGINPAGE} Children={Login} {...props} />
              );
            }}
          />

          <Route
            path={`${match.path}`}
            render={() => {
              return <Redirect to={`/`} />;
            }}
          />
        </Switch>
      </AppContext.Provider>
    </BrowserRouter>
  );
};

export default LoggedOutPages;
