import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";

const UserAccounts = lazy(() => import("../../pages/UserAccounts"));

interface UserAccountsProps {
  route: typeof Routes.USERACCOUNTS;
  path: string;
  exact: boolean;
}

const UserAccountsRoute: React.FC<UserAccountsProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute
      path={path}
      route={route}
      exact={exact}
      Component={UserAccounts}
    />
  );
};

export default UserAccountsRoute;
