import React, { useEffect, useState } from 'react';
import { FaExternalLinkSquareAlt, FaInfoCircle } from 'react-icons/fa';
import home_banner_logged_in from '../../static/images/home_banner_logged_In.jpg';
import styles from './HomePage.module.css';
import holistic_qip_img from '../../static/images/holistic_qip.png';
import holistic_management_portal from '../../static/images/holistic_management_portal.png';
import { redirect } from '../../helpers/redirect';
import { getUserLoggedIn } from '../../services/auth';
import { useStoreActions, useStoreState } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { get as pmGet } from '../../api/paymentMethod';
import { isEmpty } from 'lodash';
import ChangePlanRequest from '../../components/Popups/ChangePlanRequest/ChangePlanRequest';
import Confirm from '../../components/Popups/Confirm';
import { LANDINGPAGE } from '../../enum/action';

const HomePage: React.FC = (): JSX.Element => {
	const authUser = getUserLoggedIn();

	const history = useHistory();

	const [hasPm, setHasPm] = useState(false);
	const [selectedService, setSelectedService] = useState<any>({});

	const [gettingPMLoading, setGettingPMLoading] = useState(false);

	const [showChangePlanRequest, setShowChangePlanRequest] = useState(false);

	const [showConfirm, setShowConfirm] = useState(false);

	const services =
		useStoreState(state => state.userStore.servicesDetails) || [];
	const userData: any = useStoreState((state: any) => state?.authStore);

	useEffect(() => {
		const filterService = services.filter(
			(service: any) => service.id === userData?.centerId?.id
		);
		setSelectedService(filterService[0]);
	}, [userData?.centerId?.id]);

	const redirectToFailedPayment = services?.some(service => {
		return service.redirectPaymentFailedPage;
	});

	const canAccessQIP =
		selectedService?.canAccessQIP || selectedService?.isOnTrial;
	const canAccessPortal =
		selectedService?.canAccessPortal || selectedService?.isOnTrial;

	const onRedirect = (app: string) => {
		redirect(app, authUser.id, userData?.centerId?.id, authUser.stripe_id);
	};

	useEffect(() => {
		const fetchPM = async () => {
			setGettingPMLoading(true);
			const response = await pmGet(authUser.id);
			setHasPm(!isEmpty(response));
			setGettingPMLoading(false);
		};
		fetchPM();
	}, []);

	return (
		<>
			<Confirm
				isOpen={showConfirm}
				text={`Thank you! Your message has been sent, and we’ll be in touch soon.`}
				confirmText={`CLOSE`}
				onConfirm={() => setShowConfirm(false)}
			/>
			<ChangePlanRequest
				isOpen={showChangePlanRequest}
				onClose={() => setShowChangePlanRequest(false)}
				onConfirm={() => {
					setShowChangePlanRequest(false);
					setShowConfirm(true);
				}}
			/>
			<div
				className={`${styles.pageBanner} pageBanner`}
				style={{ backgroundImage: `url('${home_banner_logged_in}')` }}
			>
				<div className={`${styles.container}`}>
					<div className={`${styles.pageBannerContent}`}>
						<h2>Welcome back to the Holistic Management Hub</h2>
						<p>
							Access and control all Holistic products in one
							place
						</p>
					</div>
				</div>
			</div>
			<div className={`${styles.container}`}>
				<div className={`${styles.mainContent}`}>
					<ul className={`${styles.appList}`}>
						<li className={`${styles.qip}`}>
							<span className={`${styles.appName}`}>
								Holistic QIP
							</span>
							<span className={`${styles.appDesc}`}>
								Provides you with the direction, tools and help
								you need to successfully manage and complete the
								QIP/SAT process.
							</span>
							<div className={`${styles.appButtons}`}>
								<a
									href="https://holisticmanagement.net.au/holistic-qip/"
									target="_blank"
									rel="noreferrer"
								>
									<FaInfoCircle size={17} />
									LEARN MORE
								</a>
								<a
									className={`cursor-pointer ${
										!(
											selectedService?.canAccessQIP ||
											selectedService?.isOnTrial
										)
											? styles.notActive
											: ''
									}`}
									onClick={() => {
										if (
											!selectedService?.canAccessQIP &&
											selectedService?.hasSub &&
											!hasPm
										) {
											setShowChangePlanRequest(true);
										} else if (
											selectedService?.canAccessQIP ||
											selectedService?.isOnTrial
										) {
											onRedirect('qip');
										} else {
											if (
												!canAccessQIP &&
												redirectToFailedPayment
											) {
												history.push(`/payment/failed`);
											} else if (
												!selectedService?.has_qip
											) {
												history.push(`/plans`, {
													action: LANDINGPAGE.change_plan,
													centerId:
														selectedService?.has_portal
															? selectedService?.id
															: 0,
													serviceName:
														selectedService?.has_portal
															? selectedService?.name
															: '',
													productName:
														selectedService?.has_portal
															? selectedService?.has_portal &&
															  selectedService?.hasSub
																? selectedService
																		?.subscription
																		?.product
																: ''
															: '',
													interval:
														selectedService?.has_portal
															? selectedService?.has_portal &&
															  selectedService?.hasSub
																? selectedService
																		?.subscription
																		?.subscription
																		?.plan
																		?.interval
																: ''
															: '',
												});
											} else {
												history.push(`/plans`, {
													action: LANDINGPAGE.change_plan,
													centerId:
														selectedService?.has_portal
															? selectedService?.id
															: 0,
													serviceName:
														selectedService?.has_portal
															? selectedService?.name
															: '',
													productName:
														selectedService?.has_portal
															? selectedService?.has_portal &&
															  selectedService?.hasSub
																? selectedService
																		?.subscription
																		?.product
																: ''
															: '',
													interval:
														selectedService?.has_portal
															? selectedService?.has_portal &&
															  selectedService?.hasSub
																? selectedService
																		?.subscription
																		?.subscription
																		?.plan
																		?.interval
																: ''
															: '',
												});
											}
										}
									}}
								>
									<FaExternalLinkSquareAlt size={17} />
									{selectedService?.has_qip
										? 'LAUNCH'
										: selectedService?.isOnTrial
										? 'TRIAL'
										: 'SIGN UP'}
								</a>
							</div>
							<figure className={`${styles.img_wrap}`}>
								<img
									src={holistic_qip_img}
									alt="holistic qip banner"
								/>
							</figure>
						</li>
						<li className={`${styles.portal}`}>
							<span className={`${styles.appName}`}>
								Holistic Management Portal
							</span>
							<span className={`${styles.appDesc}`}>
								Provides Leaders, Managers and Educators with
								instant access to exclusive Coaching & Mentoring
								resources.{' '}
							</span>
							<div className={`${styles.appButtons}`}>
								<a
									href="https://holisticmanagement.net.au/holistic-management-portal/"
									target="_blank"
									rel="noreferrer"
								>
									<FaInfoCircle size={17} />
									LEARN MORE
								</a>
								<a
									className={`cursor-pointer   ${
										!(
											selectedService?.canAccessPortal ||
											selectedService?.isOnTrial
										)
											? styles.notActive
											: ''
									}`}
									onClick={() => {
										if (
											!selectedService?.canAccessPortal &&
											selectedService?.hasSub &&
											!hasPm
										) {
											setShowChangePlanRequest(true);
										} else if (
											selectedService?.canAccessPortal ||
											selectedService?.isOnTrial
										) {
											onRedirect('portal');
										} else {
											if (
												!canAccessPortal &&
												redirectToFailedPayment
											) {
												history.push(`/payment/failed`);
											} else if (
												!selectedService?.has_portal
											) {
												history.push(`/plans`, {
													action: 'change_plan',
													centerId:
														selectedService?.has_qip
															? selectedService?.id
															: 0,
													serviceName:
														selectedService?.has_qip
															? selectedService?.name
															: '',
													productName:
														selectedService?.has_qip
															? selectedService?.has_qip &&
															  selectedService?.hasSub
																? selectedService
																		?.subscription
																		?.product
																: ''
															: '',
													interval:
														selectedService?.has_qip
															? selectedService?.has_qip &&
															  selectedService?.hasSub
																? selectedService
																		?.subscription
																		?.subscription
																		?.plan
																		?.interval
																: ''
															: '',
												});
											} else {
												history.push(`/plans`, {
													action: 'change_plan',
													centerId:
														selectedService?.has_qip
															? selectedService?.id
															: 0,
													serviceName:
														selectedService?.has_qip
															? selectedService?.name
															: '',
													productName:
														selectedService?.has_qip
															? selectedService?.has_qip &&
															  selectedService?.hasSub
																? selectedService
																		?.subscription
																		?.product
																: ''
															: '',
													interval:
														selectedService?.has_qip
															? selectedService?.has_qip &&
															  selectedService?.hasSub
																? selectedService
																		?.subscription
																		?.subscription
																		?.plan
																		?.interval
																: ''
															: '',
												});
											}
										}
									}}
								>
									<FaExternalLinkSquareAlt size={17} />
									{selectedService?.has_portal
										? 'LAUNCH'
										: selectedService?.isOnTrial
										? 'TRIAL'
										: 'SIGN UP'}
								</a>
							</div>
							<figure className={`${styles.img_wrap}`}>
								<img src={holistic_management_portal} />
							</figure>
						</li>
					</ul>
				</div>
			</div>{' '}
		</>
	);
};

export default HomePage;
