import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";
import HelpThankYou from "../../components/HelpThankyou";

const ForgotPasswordPage = lazy(() => import("../../pages/ForgotPassword"));

interface ForgotPasswordPageProps {
  route: typeof Routes.FORGOTPASSWORDPAGE;
  path: string;
  exact: boolean;
}

const ForgotPasswordPageRoute: React.FC<ForgotPasswordPageProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute
      path={path}
      route={route}
      exact={exact}
      Component={ForgotPasswordPage}
    />
  );
};

export default ForgotPasswordPageRoute;
