import React, { useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { styleCardCvc, styleCardExpiry, styleCardNumber } from "./cardStyle";
import styles from "./index.module.css";
import Required from "../Required";

type CardProps = {
  cardName?: string;
  setCardName?: React.Dispatch<React.SetStateAction<string>>;
  showErrors?: boolean;
};

const Card = (props: CardProps) => {
  const { cardName, setCardName, showErrors } = props;
  const [stripeErrors, setStripeErrors] = useState<{
    number?: string;
    expiry?: string;
    cvc?: string;
  }>();

  const handleStripeInput = (key: string, text?: string) => {
    setStripeErrors({
      ...stripeErrors,
      [key]: text,
    });
  };

  return (
    <div className={styles.formWrapper}>
      {setCardName && (
        <div className={styles.inputWrapper}>
          <label>
            Card name <span className="text-red-400">*</span>
          </label>
          <input
            className="p-3.5"
            type="text"
            placeholder="Name"
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
          />
          {showErrors && !cardName && (
            <Required customText="Name is required." className="text-sm" />
          )}
        </div>
      )}
      <div className={styles.inputWrapper}>
        <label>
          Card number <span className="text-red-400">*</span>
        </label>
        <CardNumberElement
          className="w-full border border-black p-3.5 rounded-sm"
          options={styleCardNumber}
          onChange={({ error }) => handleStripeInput("number", error?.message)}
        />
        {showErrors && stripeErrors?.number && (
          <Required customText={stripeErrors?.number} className="text-sm" />
        )}
      </div>
      <div className={styles.inputWrapper}>
        <label>
          Expiry date <span className="text-red-400">*</span>
        </label>
        <CardExpiryElement
          className="w-full border border-black p-3.5 rounded-sm"
          options={styleCardExpiry}
          onChange={({ error }) => handleStripeInput("expiry", error?.message)}
        />
        {showErrors && stripeErrors?.expiry && (
          <Required customText={stripeErrors?.expiry} className="text-sm" />
        )}
      </div>
      <div className={styles.inputWrapper}>
        <label>
          Card code (CVC) <span className="text-red-400">*</span>
        </label>
        <CardCvcElement
          className={`w-full border border-black p-3.5 rounded-sm`}
          options={{ ...styleCardCvc, placeholder: "Code" }}
          onChange={({ error }) => handleStripeInput("cvc", error?.message)}
        />
        {showErrors && stripeErrors?.cvc && (
          <Required customText={stripeErrors?.cvc} className="text-sm" />
        )}
      </div>
    </div>
  );
};

export default Card;
