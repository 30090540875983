import { useQuery } from "react-query";
import { useStoreState } from ".";
import { paymentMethodApi } from "../api/paymentMethod";
import { STALE_TIME } from "../services/query";

export default function useFetchPaymentMethods(enabled = true) {
  const userInfo = useStoreState((state) => state.authStore.userInfo);

  return useQuery(
    ["paymentMethods"],
    () => paymentMethodApi.get(userInfo?.id),
    {
      enabled: enabled && !!userInfo?.id,
      staleTime: STALE_TIME.HIGH,
    }
  );
}
