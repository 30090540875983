import React from "react";
import { Button } from "semantic-ui-react";
import BaseModal from "../../BaseModal";
import styles from "./Confirm.module.css";

type ConfirmProps = {
  text?: string;
  isOpen: boolean;
  confirmText?: string;
  declineText?: string;
  onConfirm: () => void;
  onDecline?: () => void;
  onClose?: () => void;
  children?: React.ReactNode | React.ReactNode[];
};

export default function Confirm(props: ConfirmProps) {
  return (
    <BaseModal isOpen={props.isOpen} onClose={props.onClose}>
      <div className="flex flex-col items-center mb-8 mx-8 mt-4">
        {props.text && (
          <p className={`${styles.textPrompt} mb-5`}>{props.text}</p>
        )}
        {props.children}
        <div className={styles.actionBtns}>
          {props.confirmText && (
            <Button className={styles.confirmBtn} onClick={props.onConfirm}>
              {props.confirmText}
            </Button>
          )}
          {props.onDecline && (
            <Button className={styles.declineBtn} onClick={props.onDecline}>
              {props.declineText || "CANCEL"}
            </Button>
          )}
        </div>
      </div>
    </BaseModal>
  );
}
