import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";

const Trial = lazy(() => import("../../pages/Trial"));

interface TrialProps {
  route: typeof Routes.TRIAL;
  path: string;
  exact: boolean;
}

const TrialRoute: React.FC<TrialProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute path={path} route={route} exact={exact} Component={Trial} />
  );
};

export default TrialRoute;
