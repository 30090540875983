import { useEffect, useState } from "react";
import { getAllCenter } from "../../api/request";
import { useStoreState } from "easy-peasy";
import { useStoreActions } from "../../hooks";
import BaseModal from "../BaseModal";
import Center from "./center";
import './center.css';
import Confirm from "../Popups/Confirm";


type valueString = {
    center_id: number,
    center_name: string,
    datetime_end: string
}

const ChangeCenter = () => {
    // get the current center
    const [centers, setCenters] = useState<valueString[]>([]);
    const [isOpenCreated, setIsOpenCreated] = useState(false);
    const setUserInfo = useStoreActions(
        (actions) => actions.authStore.setCenterId
    );
    const userData: any = useStoreState((state: any) => state?.authStore); 
    const services: any = useStoreState((state: any) => state.userStore.servicesDetails) || [];
    
    useEffect(() => {
        const getCenters = async () => {
            const response = await getAllCenter(userData?.userInfo?.id);
            
            if (response?.centers?.length > 0) {
                // add first center to the list if user is not in any center
                const isCenterExists = response?.centers?.filter((center: any) => center.center_id === userData?.centerId?.id);
                if (isCenterExists.length === 0) {
                    setUserInfo({ id: response?.centers[0]?.center_id || null });
                }
                setCenters(response?.centers || []);
            } else {
                setCenters([]);
            }
        };
        getCenters();
    }, [userData]);

    const onCenterChange = (selectedOption: number) => {
        if (!selectedOption) return; // if no option is selected, do nothing
        setUserInfo({ id: selectedOption || null }); // set the selected option to the user info
        setIsOpen(false); // close the modal
        // show notification
        setIsOpenCreated(true);
        // page reload after 2 seconds
        window.location.reload();       
    };

    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => {
        setIsOpen(false);
    };
    const onOpen = (e: any) => {
        e.preventDefault();
        setIsOpen(true);
    };

    // get the current center name from the centers array
    const currentCenter = centers.filter((center) => center.center_id === userData?.centerId?.id);
    const currentCenterName = currentCenter.length > 0 ? currentCenter[0]?.center_name : "";

    // if there is no center, show the modal
    if (!currentCenterName) {
        return null;
    }

    return (
        <>
            <Confirm
                text="Service changed successfully."
                confirmText="OK"
                isOpen={isOpenCreated}
                onConfirm={() => setIsOpenCreated(false)}
                onClose={() => setIsOpenCreated(false)}
            />
            
            {/* create a popupdown button */}
            <div className="topHeaderRight dropdowb__btn">
                <span className="selectedService">{currentCenterName}</span><span onClick={onOpen} data-toggle="modal" data-target="#servicesListModal" className="changeService flex items-center">Change <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" className="svg-inline--fa fa-caret-down fa-w-10" role="img" width="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#ffffff99" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg></span>
            </div>
            <BaseModal isOpen={isOpen} onClose={onClose}>
                <div className="modalContainer">
                    <text className="headerText">Change service</text>
                    <div className="flex flex-col items-center mb-8 mx-8 mt-4">
                        {
                            centers.map((center: any) => {
                                return <Center key={center.center_id} center={center} onCenterChange={onCenterChange} />
                            })
                        }
                    </div>
                </div>
            </BaseModal>
        </>
    )
}

export default ChangeCenter;