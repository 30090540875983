import React, { Suspense } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Routes } from "../../context/App";
import { FaSpinner } from "react-icons/fa";
import { PaymentFailed, TrialWarning } from "../Banner/PaymentWarning";
import { useStoreActions, useStoreState } from "../../hooks";
import { getHubViewer, isUserLoggedIn } from "../../services/auth";
import Trial from "../../pages/Trial";
import ViewerInfoBar from "../ViewerInfoBar";
import Plans from "../../pages/Plans";
import { isEmpty } from "lodash";

interface LayoutProps {
  Children: React.ElementType;
  route:
    | typeof Routes.HOMEPAGE
    | typeof Routes.LANDINGPAGE
    | typeof Routes.USERACCOUNTS
    | typeof Routes.HELPPAGE
    | typeof Routes.LOGINPAGE
    | typeof Routes.FORGOTPASSWORDPAGE
    | typeof Routes.NEWPASSWORDPAGE
    | typeof Routes.SUBSCRIPTIONS
    | typeof Routes.PLANS
    | typeof Routes.PAYMENT
    | typeof Routes.THANKYOU
    | typeof Routes.TRIAL
    | typeof Routes.RESUBSCRIBE;
}

const Layout: React.FC<LayoutProps> = (props): JSX.Element => {
  const { Children, route } = props;

  const services = useStoreState((state) => state.userStore.servicesDetails);

  const hubViewer = getHubViewer();

  const trialEnded =
    isUserLoggedIn() &&
    services?.length === 1 &&
    services[0].trialEnded &&
    route &&
    route !== Routes.PAYMENT;

  const diffTime30withSub = services?.some(
    (service) => service.redirectToPricingPlanPage
  );

  const servicesLengthOne = services?.length === 1;

  const redirectToSignUp: boolean =
    isUserLoggedIn() &&
    diffTime30withSub &&
    servicesLengthOne &&
    route &&
    route !== Routes.PAYMENT
      ? true
      : false;

  const hideMenus: boolean =
    isUserLoggedIn() && diffTime30withSub && servicesLengthOne ? true : false;

  return (
    <div className={`flex flex-col min-h-screen w-full`}>
      {
        // TODO: SHOW BANNER COMPONENT WARNINGS when data is available
        /* <PaymentFailed />
      <TrialWarning /> */
      }
      {services &&
        services.length === 1 &&
        services[0].trialRemainingBanner.show &&
        TrialWarning(services[0].trialRemainingBanner.remaining)}
      {services &&
        services.some((service) => service.showPaymentFailedBanner) && (
          <PaymentFailed />
        )}
      <Header route={route} hideMenus={hideMenus} />
      {!isEmpty(hubViewer) && <ViewerInfoBar />}
      <Suspense
        fallback={
          <div
            className={`w-screen h-screen flex flex-col justify-center items-center `}
          >
            <FaSpinner className={`text-6xl text-qipGreen animate-spin`} />{" "}
            <span className={`mt-2`}>Loading...</span>{" "}
          </div>
        }
      >
        {trialEnded ? <Trial /> : redirectToSignUp ? <Plans /> : <Children />}
      </Suspense>
      <Footer hideMenus={hideMenus} />
    </div>
  );
};

export default Layout;
