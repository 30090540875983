import { Link, useHistory, useLocation } from "react-router-dom";
import "./SignUp.css";
import PageBanner from "../PageBanner/PageBanner";
import { PRODUCTS } from "../../Plans/constants/pricing";
import { useState } from "react";
import { useStoreActions, useStoreState } from "../../../hooks";
import { setUserToken } from "../../../services/auth";
import { registerUser } from "../../../api/request";
import { FaEye, FaEyeDropper, FaEyeSlash, FaSpinner } from "react-icons/fa";
import { useEffect } from "react";
import { LANDINGPAGE } from "../../../enum/action";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const serviceCat = [
  { text: "Long Daycare", value: 1, key: "1" },
  { text: "Family Daycare", value: 2, key: "2" },
  { text: "Pre-school", value: 3, key: "3" },
  { text: "OOSH", value: 4, key: "4" },
];

const New = () => {
  const query = useQuery();
  // TODO: replace with actual query params from wordpress
  // TODO: form state

  const location: any = useLocation();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  // const product = query.get("product");
  // const mentoring = query.get("mentoring");
  // const isMonthly = query.get("monthly");
  // console.log({ product, mentoring, isMonthly });

  const [fieldsChecked, setFieldsChecked] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState(false);

  const setUserInfo = useStoreActions(
    (actions) => actions.authStore.saveUserInfo
  );

  const setShowAction = useStoreActions(
    (actions) => actions.appStore.setShowAction
  );

  const setAuthUser = useStoreActions(
    (actions) => actions.authStore.setAuthUser
  );

  const [signUpInfo, setSignUpInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    repeatEmail: "",
    phone: "",
    password: "",
    serviceName: "",
    approvaNumber: "",
    serviceType: 1,
    preferredDate: "",
    preferredTime: "",
  });

  const getRedirectCondition = () => {
    if (location.state !== undefined) {
      if (location.state.action === LANDINGPAGE.new_signup) {
        const product = PRODUCTS.filter(
          (p: any) => p.alias === location.state.product
        )[0];
        if (product) {
          return {
            product,
            name:
              product.title +
              (location.state.mentoring === "1" ? " + Mentoring" : ""),
            includeMentoring: location.state.mentoring === "1",
            isMonthly: location.state.freq === "monthly",
            price: location.state.price,
            priceId:
              product.price_ids[
                location.state.mentoring === "1"
                  ? "withMentoring"
                  : "withNoMentoring"
              ]["withTrial"][
                location.state.freq === "monthly" ? "monthly" : "yearly"
              ],
          };
        } else {
          return {
            product: {},
            name: "",
            includeMentoring: false,
            isMonthly: false,
            price: "",
            priceId: "",
          };
        }
      } else {
        return {
          product: {},
          name: "",
          includeMentoring: false,
          isMonthly: false,
          price: "",
          priceId: "",
        };
      }
    } else {
      return {
        product: {},
        name: "",
        includeMentoring: false,
        isMonthly: false,
        price: "",
        priceId: "",
      };
    }
  };

  const hasQIPAccess = () => {
    return getRedirectCondition().name.toLowerCase().includes("qip");
  };
  const hasPortalAccess = () => {
    return getRedirectCondition().name.toLowerCase().includes("portal");
  };

  const onSignUp = async () => {
    setFieldsChecked(true);

    if (!signUpInfo.email || !signUpInfo.repeatEmail) {
      return;
    }
    if (signUpInfo.email !== signUpInfo.repeatEmail) {
      return;
    }

    if (
      !signUpInfo.firstName ||
      !signUpInfo.lastName ||
      !signUpInfo.phone ||
      !signUpInfo.email ||
      !signUpInfo.password! ||
      !signUpInfo.serviceName ||
      (signUpInfo.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpInfo.email))
    ) {
      return;
    }

    try {
      setLoading(true);
      const response = await registerUser({
        price: getRedirectCondition().price,
        firstName: signUpInfo.firstName,
        lastName: signUpInfo.lastName,
        email: signUpInfo.email,
        phoneNumber: signUpInfo.phone,
        password: signUpInfo.password,
        serviceName: signUpInfo.serviceName,
        serviceApprovalNumber: signUpInfo.approvaNumber,
        serviceCategory: signUpInfo.serviceType,
        preferredDate: signUpInfo.preferredDate,
        preferredTime: signUpInfo.preferredTime,
        // has_portal: hasPortalAccess(),
        // has_qip: hasQIPAccess(),
        has_portal: true,
        has_qip: true,
      });

      if (response.status) {
        setLoading(false);
        setAuthUser(response.auth_user);
        setUserInfo(response.auth_user);
        setUserToken(response.access_token, () => {});

        localStorage.setItem(
          "signUpDetails",
          JSON.stringify({
            action: LANDINGPAGE.new_signup,
            title: getRedirectCondition().name,
            isMonthly: getRedirectCondition().isMonthly,
            price: getRedirectCondition().price,
            priceId: getRedirectCondition().priceId,
            serviceName: signUpInfo.serviceName,
            trialEnded: false,
          })
        );

        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("signUpDetails")) {
      const signUpDetails = JSON.parse(
        localStorage.getItem("signUpDetails") || "{}"
      );
      localStorage.removeItem("signUpDetails");
      history.push(`/payment/details`, signUpDetails);
    }
  }, []);

  useEffect(() => {
    setShowAction({
      doAction: true,
      reference: LANDINGPAGE.new_signup,
    });
  }, []);

  return (
    <>
      <PageBanner title="Payment" />
      <div className="signUp container">
        <div className="mainContent">
          <div className="signUpContainer">
            <div className="signUpText">
              {/* <h2>You are purchasing a Holistic QIP plan for $97 per month</h2> */}
              <h2>{`You are purchasing a ${
                getRedirectCondition().name
              } plan for $${getRedirectCondition().price.toLocaleString()} per ${
                getRedirectCondition().isMonthly ? "month" : "year"
              }`}</h2>
              <p>
                {`Complete your sign up details before continuing to payment.`}
              </p>
            </div>
            <div className="signUpInput">
              <div className="inputRow">
                <div className="inputCol">
                  <label>
                    First name <span className="required">*</span>
                  </label>
                  <input
                    onChange={(e) =>
                      setSignUpInfo({
                        ...signUpInfo,
                        firstName: e.target.value,
                      })
                    }
                    type="text"
                  />
                  <div className={`text-red-400 italic py-1 px-1 h-4`}>
                    {fieldsChecked && !signUpInfo.firstName && (
                      <> This field is required. </>
                    )}
                  </div>
                </div>
                <div className="inputCol">
                  <label>Last name</label>
                  <input
                    onChange={(e) =>
                      setSignUpInfo({
                        ...signUpInfo,
                        lastName: e.target.value,
                      })
                    }
                    type="text"
                  />
                  <div className={`text-red-400 italic py-1 px-1 h-4`}>
                    {fieldsChecked && !signUpInfo.lastName && (
                      <> This field is required. </>
                    )}
                  </div>
                </div>
              </div>
              <div className="inputRow">
                <div className="inputCol">
                  <label>
                    Email address <span className="required">*</span>
                  </label>
                  <input
                    onChange={(e) =>
                      setSignUpInfo({
                        ...signUpInfo,
                        email: e.target.value,
                      })
                    }
                    type="email"
                  />
                  <div className={`text-red-400 italic py-1 px-1 h-4`}>
                    {fieldsChecked &&
                      signUpInfo.email !== signUpInfo.repeatEmail &&
                      signUpInfo.repeatEmail &&
                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpInfo.email) && (
                        <> Email do not match. </>
                      )}
                    {fieldsChecked && !signUpInfo.email && (
                      <> This field is required. </>
                    )}
                    {fieldsChecked &&
                      signUpInfo.email &&
                      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpInfo.email) && (
                        <> Please provide a valid email address. </>
                      )}
                  </div>
                </div>
                <div className="inputCol">
                  <label>
                    Repeat email address <span className="required">*</span>
                  </label>
                  <input
                    onChange={(e) =>
                      setSignUpInfo({
                        ...signUpInfo,
                        repeatEmail: e.target.value,
                      })
                    }
                    type="email"
                  />
                  <div className={`text-red-400 italic py-1 px-1 h-4`}>
                    {fieldsChecked && !signUpInfo.repeatEmail && (
                      <> This field is required. </>
                    )}
                  </div>
                </div>
              </div>
              <div className="inputRow">
                <div className="inputCol">
                  <label>
                    Phone number <span className="required">*</span>
                  </label>
                  <input
                    onChange={(e) =>
                      setSignUpInfo({
                        ...signUpInfo,
                        phone: e.target.value,
                      })
                    }
                    type="tel"
                    autoComplete="new-password"
                  />
                  <div className={`text-red-400 italic py-1 px-1 h-4`}>
                    {fieldsChecked && !signUpInfo.phone && (
                      <> This field is required. </>
                    )}
                  </div>
                </div>
                <div className="inputCol ">
                  <label>
                    Choose a password <span className="required">*</span>
                  </label>
                  <input
                    autoComplete="new-password"
                    onChange={(e) =>
                      setSignUpInfo({
                        ...signUpInfo,
                        password: e.target.value,
                      })
                    }
                    type={showPassword ? `text` : `password`}
                  />
                  <div
                    onClick={() => setShowPassword(!showPassword)}
                    className={`relative toggleShowPassword cursor-pointer pr-2`}
                  >
                    {showPassword ? (
                      <FaEyeSlash className={`text-xl`} />
                    ) : (
                      <FaEye className={`text-xl`} />
                    )}
                  </div>

                  <div className={`text-red-400 italic py-1 px-1 h-4`}>
                    {fieldsChecked && !signUpInfo.password && (
                      <> This field is required. </>
                    )}
                  </div>
                </div>
              </div>
              <div className="inputRow">
                <div className="inputCol">
                  <label>
                    Service name <span className="required">*</span>
                  </label>
                  <input
                    autoComplete="new-password"
                    onChange={(e) =>
                      setSignUpInfo({
                        ...signUpInfo,
                        serviceName: e.target.value,
                      })
                    }
                    type="phone"
                  />
                  <div className={`text-red-400 italic py-1 px-1 h-4`}>
                    {fieldsChecked && !signUpInfo.serviceName && (
                      <> This field is required. </>
                    )}
                  </div>
                </div>
                <div className="inputCol">
                  <label>Service approval number</label>
                  <input
                    onChange={(e) =>
                      setSignUpInfo({
                        ...signUpInfo,
                        approvaNumber: e.target.value,
                      })
                    }
                    type="text"
                  />
                </div>
              </div>
              <div className="inputRow">
                <div className="inputCol">
                  <label>
                    Service type <span className="required">*</span>
                  </label>
                  <select
                    onChange={(e) =>
                      setSignUpInfo({
                        ...signUpInfo,
                        serviceType: Number(e.target.value),
                      })
                    }
                  >
                    {serviceCat.map((cat) => {
                      return <option value={cat.value}>{cat.text}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="walkthrough">
                <span>Free personal 15 min walkthrough</span>
                <p>
                  Let us know a preferred time (Before / During or After hours)
                  for one of our consultants to show you everything our Holistic
                  Products have to offer, to ensure you get the most out of your
                  software.
                </p>
              </div>

              <div className="inputRow">
                <div className="inputCol">
                  <label>Preferred date</label>
                  <input
                    onChange={(e) =>
                      setSignUpInfo({
                        ...signUpInfo,
                        preferredDate: e.target.value,
                      })
                    }
                    type="text"
                  />
                </div>
                <div className="inputCol">
                  <label>Preferred time of day</label>
                  <input
                    onChange={(e) =>
                      setSignUpInfo({
                        ...signUpInfo,
                        preferredTime: e.target.value,
                      })
                    }
                    type="text"
                  />
                </div>
              </div>
              <div className="inputBtn">
                <button
                  disabled={loading}
                  onClick={onSignUp}
                  className={`btnBlue`}
                >
                  {loading ? (
                    <div className={`flex justify-center items-center`}>
                      <FaSpinner className={`animate-spin text-white`} />{" "}
                      <span className={`ml-2`}>Please wait...</span>
                    </div>
                  ) : (
                    <> CONTINUE TO PAYMENT </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default New;
