import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "easy-peasy";
import mainStore from "./store/mainStore";

const stripePromise = loadStripe(
  process.env.REACT_APP_PRICEID === "LIVE"
    ? "pk_live_51HjLRMDeO3XG77wNHweMnNsYOuMyvxnYrbED6yi3bkM7qjoTi5A3hiTfI5DHZfJrCz3cnwl2urwgPJZAUtE6zmrE00mME9I8tG"
    : "pk_test_51HjLRMDeO3XG77wNhwW9qSymKTSUt1JpKn0AkJXx98oX98jZnR2OH8FuPrZ2nuetqtnKedHaVDcXWjKAV9JRo8cR00w3WbJKpG"
);

const fonts = [{ cssSrc: `https://fonts.googleapis.com/css?family=Rubik:300` }];
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={mainStore}>
      <Elements stripe={stripePromise} options={{ fonts }}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Elements>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
