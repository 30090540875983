import styles from "./PageBanner.module.css";
import page_banner_img from "../../../static/images/page_banner_img.jpg";

const PageBanner = (props: { title: string }) => {
  return (
    <div
      className={styles.pageBanner}
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(34,34,34, 0),rgba(34,34,34, 100)), url(${page_banner_img})`,
      }}
    >
      <h1 className={styles.titleBanner}>{props.title}</h1>
    </div>
  );
};

export default PageBanner;
