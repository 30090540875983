import appStore, { AppModel } from "./appModel";
import authStore, { AuthModel } from "./authModel";
import userStore, { UserModel } from "./userModel";

export interface StoreModel {
  appStore: AppModel;
  authStore: AuthModel;
  userStore: UserModel;
}

const storeModel: StoreModel = {
  appStore,
  authStore,
  userStore,
};

export default storeModel;
