import { FaAngleDown, FaDownload, FaSpinner } from "react-icons/fa";
import AddService from "./AddService";
import Pagination from "../../components/Pagination";
import { useState } from "react";
import useFetchPastPayments from "../../hooks/useFetchPastPayments";
import Spinner from "../../components/Spinner";

// sort of polyfill
const products = [
  "Holistic QIP & HMC Portal + Mentoring",
  "Holistic QIP & Management Portal + Mentoring",
];

const products1 = [
  "Holistic QIP & HMC Portal",
  "Holistic QIP & Holistic Management Portal",
];

const products2 = [
  "Holistic QIP + Mentoring",
  "HMC Portal + Mentoring",
  "Holistic Management Portal + Mentoring",
];

const products3 = ["Holistic QIP", "HMC Portal", "Holistic Management Portal"];

type PastPaymentsProps = {
  searchStr: string;
};

const PAGE_SIZE = 10;
const paginate = (array: any[], page_number = 1) => {
  return array.slice((page_number - 1) * PAGE_SIZE, page_number * PAGE_SIZE);
};

export default function PastPayments(props: PastPaymentsProps) {
  const [page, setPage] = useState(1);
  const [isDownloading, setIsDownloading] = useState<string>();
  const { data: rawData, isLoading } = useFetchPastPayments();

  const data = (rawData || []).filter((pp) => {
    const keyword = props.searchStr.toLowerCase();
    const match =
      pp.invoice.number?.toLowerCase().includes(keyword) ||
      pp.invoice.account_name?.toLowerCase().includes(keyword) ||
      pp.center?.toLowerCase().includes(keyword);
    return match;
  });

  const pastPayments = paginate(data, page);
  const pageCount = Math.ceil(data.length / PAGE_SIZE);

  const onDownload = async (url: string, fileName: string) => {
    const link = document.createElement("a");
    link.className = "hidden";
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => setIsDownloading(undefined), 3000);
  };

  const listProducts = (lists: any) => {
    let p: any = [];
    lists.map((list: any) => {
      let pfound = false;
      products.forEach((product) => {
        if (list.description.includes(product)) {
          pfound = true;
          if (!p.some((prod: string) => prod === product)) {
            p.push(product);
          }
        }
      });
      if (!pfound) {
        products1.forEach((product) => {
          if (list.description.includes(product)) {
            pfound = true;
            if (!p.some((prod: string) => prod === product)) {
              p.push(product);
            }
          }
        });
      }
      if (!pfound) {
        products2.forEach((product) => {
          if (list.description.includes(product)) {
            pfound = true;
            if (!p.some((prod: string) => prod === product)) {
              p.push(product);
            }
          }
        });
      }
      if (!pfound) {
        products3.forEach((product) => {
          if (list.description.includes(product)) {
            pfound = true;
            if (!p.some((prod: string) => prod === product)) {
              p.push(product);
            }
          }
        });
      }
    });

    return Array.from(new Set(p)).map((prod) => {
      return (
        <>
          - {String(prod)}
          <br />
        </>
      );
    });
  };

  return (
    <>
      <div className="tableContainer">
        <div className="tableHeader">
          <ul className="tableListCol">
            <li>
              <span className="tableMenuItem">Service</span>
            </li>
            <li>
              <div className="tableMenu tableMenuItem">Products</div>
              {/* <ul className="tableSubMenu">
                <li>Holistic QIP</li>
                <li>HMC Management Portal</li>
              </ul> */}
            </li>
            <li>
              <span className="tableMenuItem">Date Added</span>
            </li>
            <li>
              <span className="tableMenuItem">Invoice Nº</span>
            </li>
            <li>Action</li>
          </ul>
        </div>
        <div className="tableBody">
          {isLoading ? (
            <div className="flex justify-center my-10">
              <Spinner />
            </div>
          ) : (
            <>
              {pastPayments?.map((pp) => (
                <ul className="tableListCol" key={`${pp.invoice.number}`}>
                  <li>
                    <strong>{pp.center}</strong>
                  </li>
                  <li>{listProducts(pp.invoice.lines.data)}</li>
                  <li>{pp.date.replaceAll("-", "/")}</li>
                  <li>{pp.invoice.number}</li>
                  <li>
                    <button
                      className="lightBlueBtn w-full justify-center"
                      disabled={isDownloading === pp.invoice.number}
                      onClick={() => {
                        setIsDownloading(pp.invoice.number);
                        onDownload(
                          pp.invoice.invoice_pdf,
                          `Invoice-${pp.invoice.number}`
                        );
                      }}
                    >
                      {isDownloading === pp.invoice.number ? (
                        <div className="flex flex-1 justify-center">
                          <FaSpinner className="animate-spin" />
                        </div>
                      ) : (
                        <>
                          <FaDownload size={17} /> Download
                        </>
                      )}
                    </button>
                  </li>
                </ul>
              ))}
            </>
          )}
        </div>
      </div>
      {!isLoading && !!pastPayments?.length && (
        <Pagination
          onClickPage={setPage}
          pageCount={pageCount}
          activePage={page}
          onClickLeft={() => setPage(1)}
          onClickRight={() => setPage(pageCount)}
        />
      )}
      <AddService />
    </>
  );
}
