import React from "react";
import responsive from "../../static/images/responsive.png";
import "./index.css";

const MinWidthWarning: React.FC = (): JSX.Element => {
  return (
    <div className={`mainContainer`}>
      <div className={`cardContainer`}>
        <div className={`infoIcon`}>
          <img src={responsive} />
        </div>

        <text className={`infoText`}>
          Holistic HUB does not support a screen resolution below 746px. Please
          open the app on a bigger screen - tablet, laptop or desktop computer.
        </text>
      </div>
    </div>
  );
};

export default MinWidthWarning;
