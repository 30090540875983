import React, { useState } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { Modal } from "semantic-ui-react";
import styles from "./ChangePlanRequest.module.css";
import { changePlanNotification } from "../../../api/request";
import { getUserLoggedIn } from "../../../services/auth";
import { useStoreActions, useStoreState } from "../../../hooks";

interface ChangePlanRequestInterface {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  className?: string;
}

const ChangePlanRequest: React.FC<ChangePlanRequestInterface> = (
  props
): JSX.Element => {
  const { isOpen, onClose, onConfirm, className } = props;
  const [loading, setLoading] = useState(false);

  const [fieldsChecked, setFieldsChecked] = useState(false);

  const [comments, setComments] = useState("");
  const [daysoftheweek, setDaysoftheweek] = useState("");
  const [timeofday, setTimeofday] = useState("");

  const authUser = getUserLoggedIn();

  const services = useStoreState((state) => state.userStore.servicesDetails);

  const onSendRequest = async () => {
    setFieldsChecked(true);
    if (!comments || !daysoftheweek || !timeofday) {
      return;
    }
    try {
      setLoading(true);

      const response = await changePlanNotification({
        name: authUser.name,
        email: authUser.email,
        phone_number: authUser.phone || "",
        service_name: (services && services[0].name) || "",
        comments,
        daysoftheweek,
        timeofday,
      });

      if (onConfirm !== undefined) {
        onConfirm();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      centered={false}
      size={`mini`}
      open={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      closeOnDimmerClick={false}
      className={`${styles.modal}`}
    >
      <div
        className={`${styles.modalContainer}  flex flex-col justify-center items-center relative`}
      >
        <div
          onClick={() => onClose()}
          className={`${styles.close} cursor-pointer absolute top-0 right-0 flex items-center justify-center`}
        >
          <FaTimes />
        </div>
        <div
          className={`${styles.headerContainer} flex flex-col justify-center items-center`}
        >
          <h1 className={`${styles.header}`}>Change of plan request</h1>
          <h2 className={`${styles.desc}`}>
            Complete the below form and a member of our team will be in touch
            with you about options to change your plan.
          </h2>
        </div>
        <div className={`w-full`}>
          <h1 className={`${styles.labelComment} pb-3`}>
            Change of plan comments
          </h1>
          <textarea
            onChange={(e) => setComments(e.target.value)}
            className={`${styles.commentArea} outline-none p-3`}
          />
          <div className={`text-red-400 h-4 text-sm pl-1`}>
            {fieldsChecked && !comments && `This fields is required.`}
          </div>
        </div>
        <div className={`w-full`}>
          <h1 className={`${styles.contactLabel} pt-4 pb-3`}>
            {`Your preferred day & time for a member of our team to contact you`}
          </h1>
          <div className={`w-full flex justify-between items-center`}>
            <div>
              <input
                onChange={(e) => setDaysoftheweek(e.target.value)}
                placeholder={`Day`}
                className={`${styles.dayInput} py-2 px-3  outline-none`}
              />
              <div className={`text-red-400 h-4 text-sm pl-1`}>
                {fieldsChecked && !daysoftheweek && `This fields is required.`}
              </div>
            </div>
            <div>
              <input
                onChange={(e) => setTimeofday(e.target.value)}
                placeholder={`Time of day`}
                className={`${styles.timeInput} py-2 px-3 outline-none`}
              />
              <div className={`text-red-400 h-4 text-sm pl-1`}>
                {fieldsChecked && !timeofday && `This fields is required.`}
              </div>
            </div>
          </div>
          <div className={`w-full flex justify-center items-center`}>
            <button
              disabled={loading}
              onClick={onSendRequest}
              className={`${styles.sendButton}  outline-none`}
            >
              {loading ? (
                <div className={`flex justify-center items-center`}>
                  <FaSpinner className={`animate-spin text-white`} />{" "}
                  <span className={`ml-2`}>Please wait...</span>
                </div>
              ) : (
                `SEND REQUEST`
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePlanRequest;
