import { useCallback, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import page_banner_img from "../../static/images/page_banner_img.jpg";
import "./index.css";
import PastPayments from "./PastPayments";
import PaymentDetails from "./PaymentDetails";
import SubscriptionDetails from "./SubscriptionDetails";
import { useStoreActions, useStoreState } from "../../hooks";
import { useEffect } from "react";
import { getUserLoggedIn } from "../../services/auth";
import { isEmpty } from "lodash";
import { get as pmGet } from "../../api/paymentMethod";

export enum TABS {
  subscription = "details",
  paymentDetails = "payment-details",
  pastPayments = "past-payments",
}

const Subscription = () => {
  const pageParams = useParams<{ tab: string }>();
  const history = useHistory();
  const [searchStr, setSearchStr] = useState<string>("");
  const [activeTab, setActiveTab] = useState(
    pageParams.tab || TABS.subscription
  );

  const [gettingPMLoading, setGettingPMLoading] = useState(false);

  const [hasPm, setHasPm] = useState(false);

  const services = useStoreState((state) => state.userStore.servicesDetails);

  const getActiveClass = useCallback(
    (tab: TABS) => {
      return activeTab === tab ? "active" : "";
    },
    [activeTab]
  );

  const authUser = getUserLoggedIn();

  const onTabChange = (tab: string) => {
    history.push(`/subscription/${tab}`);
    setActiveTab(tab);
  };

  // if (services?.length === 1 && !services[0].hasSub) {
  //   window.location.assign(`/`);
  // }

  useEffect(() => {
    const fetchPM = async () => {
      setGettingPMLoading(true);
      const response = await pmGet(authUser.id);
      setHasPm(!isEmpty(response));
      setGettingPMLoading(false);
    };
    fetchPM();
  }, []);

  return (
    <div className="subscriptions">
      <div
        className="pageBanner"
        style={{ backgroundImage: `url(${page_banner_img})` }}
      >
        <div className="container">
          <div
            className={`searchContainer ${
              activeTab !== TABS.pastPayments ? "hidden" : ""
            }`}
          >
            <FaSearch size={17} />
            <input
              type="text"
              placeholder="Type invoice Nº, Product or Service name"
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
            />
          </div>
          <div className="tabContainer">
            <button
              onClick={() => onTabChange(TABS.subscription)}
              className={`tabHeader ${getActiveClass(TABS.subscription)}`}
            >
              Subscription Details
            </button>
            {services?.some((service) => service.hasSub) && hasPm && (
              <button
                onClick={() => onTabChange(TABS.paymentDetails)}
                className={`tabHeader ${getActiveClass(TABS.paymentDetails)}`}
              >
                Payment details
              </button>
            )}
            {services?.some((service) => service.hasSub) && hasPm && (
              <button
                onClick={() => onTabChange(TABS.pastPayments)}
                className={`tabHeader ${getActiveClass(TABS.pastPayments)}`}
              >
                Past payments
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="mainContent">
          <div className="tabContent">
            {activeTab === TABS.subscription && <SubscriptionDetails />}
            {activeTab === TABS.paymentDetails &&
              services?.some((service) => service.hasSub) &&
              hasPm && <PaymentDetails />}
            {activeTab === TABS.pastPayments &&
              services?.some((service) => service.hasSub) &&
              hasPm && <PastPayments searchStr={searchStr} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
