import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";
import clock from "../../../static/icons/clock.svg";
import { LANDINGPAGE } from "../../../enum/action";

export const PaymentFailed = () => {
  return (
    <div className="subscriptionPaymentWarning flex">
      <FaExclamationTriangle size={30} />
      <p>
        We were unable to process your payment. Please review or update your
        <Link className={`text-red-500`} to={`/subscription/payment-details`}>
          {" "}
          payment details{" "}
        </Link>
        to continue enjoying all your benefits.
      </p>
    </div>
  );
};

export const TrialWarning = (timeLeft: number) => {
  // const timeLeft = 5; // TODO: replace with actual time left data

  return (
    <div className="subscriptionPaymentWarning flex">
      <div className="bg-red-400 rounded-full h-7 w-7 flex items-center justify-center">
        <img src={clock} alt="trial clock" />
      </div>

      <p>
        You have only <span>{timeLeft} days</span> of your trial remaining.
        <Link
          className={`text-red-500`}
          to={{
            pathname: `/trial`,
            state: { action: LANDINGPAGE.trial_ending },
          }}
        >
          {" "}
          Click here
        </Link>{" "}
        to signup to one of our plans and contine enjoying all your benefits.
      </p>
    </div>
  );
};
