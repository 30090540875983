import { Action, action, computed, Computed, Thunk, thunk } from 'easy-peasy';
import { IAuthUser, ILoginCredentials } from '../../types';
import { getUserDataServices, login, getTokenStatus } from '../../api/request';
import { setUserToken } from '../../services/auth';
import { IServiceDetails } from '../../types/services';
import { userServicesAudit } from '../../helpers/userServicesAudit';
import { Redirect } from 'react-router-dom';

type centerModel = {
	id: number | null;
};

export interface AuthModel {
	centerId: centerModel;
	userInfo?: IAuthUser;
	setCenterId: Action<AuthModel, centerModel>;
	setUserInfo: Action<AuthModel, IAuthUser>;
	saveUserInfo: Thunk<AuthModel, IAuthUser>;
	setAuthUser: Action<AuthModel, IAuthUser>;
	login: Thunk<AuthModel, ILoginCredentials>;
	// tokenStatus: boolean;
	// setTokenStatus: Action<AuthModel, boolean>;
	// getTokenStatus: Thunk<AuthModel>;
}

export type UserInfo = {
	id: number;
	email: string;
	name: string;
	user_type: string;
	has_qip_access: 0 | 1 | null;
	has_portal_access: 0 | 1 | null;
}; //TODO: define all

const authStore: AuthModel = {
	// tokenStatus: false,
	// setTokenStatus: action((state, payload) => {
	//   state.tokenStatus = payload;
	// }),
	// getTokenStatus: thunk(async (actions) => {
	//   try {
	//     const response = await getTokenStatus();
	//     actions.setTokenStatus(response);
	//   } catch (error) {
	//     console.error(error);
	//     actions.setTokenStatus(false);
	//   }
	// }),
	centerId: {
		id: localStorage.getItem('selectedService')
			? parseInt(localStorage.getItem('selectedService') as string)
			: null,
	},
    setCenterId: action((state, payload) => {
        state.centerId = payload;
        localStorage.setItem('selectedService', JSON.stringify(payload?.id));
    }),
	setUserInfo: action((state, payload) => {
		state.userInfo = payload;
	}),
	saveUserInfo: thunk((actions, payload) => {
		actions.setUserInfo(payload);
	}),
	setAuthUser: action((state, payload) => {
		localStorage.setItem('authUser', JSON.stringify(payload));
	}),
	login: thunk(async (actions, payload) => {
		try {
			const response = await login(payload);
			console.log('response login ', response);
			if (response.status) {
				const data = await getUserDataServices(
					response.auth_user.id,
					response.access_token
				);

				if (data.status) {
					const serviceDetails: IServiceDetails[] | [] =
						userServicesAudit(
							data.centers,
							data.centersData,
							data.subData
						);

					console.log('service details login', serviceDetails);

					const failedPayment = serviceDetails.some(
						service => service.failedPayment
					);

					const redirectDiffTime21 = serviceDetails.some(
						service =>
							service.diffTime <= -21 && service.diffTime >= -30
					);

					if (
						serviceDetails.length === 1 &&
						serviceDetails[0].trialEnded
					) {
						if (
							response.auth_user.user_type.toLowerCase() ===
							'user'
						) {
							return {
								status: true,
								redirect: true,
								isMember: true,
								location: `/trial`,
								services: [...serviceDetails],
								response: { ...response },
							};
						} else {
							return {
								status: true,
								redirect: true,
								isMember: false,
								location: `/trial`,
								services: [...serviceDetails],
								response: { ...response },
							};
						}
					} else if (failedPayment && redirectDiffTime21) {
						if (
							response.auth_user.user_type.toLowerCase() ===
							'user'
						) {
							return {
								status: true,
								redirect: true,
								isMember: true,
								location: `/plans`,
								services: [...serviceDetails],
								response: { ...response },
							};
						} else {
							return {
								status: true,
								redirect: true,
								isMember: false,
								location: `/plans`,
								services: [...serviceDetails],
								response: { ...response },
							};
						}
					} else {
						return {
							status: true,
							redirect: false,
							services: [...serviceDetails],
							response: { ...response },
						};
					}
				} else {
					return {
						status: false,
					};
				}
			} else {
				return {
					status: false,
				};
			}
		} catch (error) {
			console.error(error);
			return {
				status: false,
			};
		}
	}),
};

export default authStore;
