import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";

const Resubscribe = lazy(() => import("../../pages/Resubscribe"));

interface ResubscribeProps {
  route: typeof Routes.RESUBSCRIBE;
  path: string;
  exact: boolean;
}

const ResubscribeRoute: React.FC<ResubscribeProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute
      path={path}
      route={route}
      exact={exact}
      Component={Resubscribe}
    />
  );
};

export default ResubscribeRoute;
