import * as React from "react";
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useStoreActions, useStoreState } from "../../hooks";

import { generatePlainText, generateHash } from "../../services/crypto";
import { setUserToken } from "../../services/auth";
import { FaSpinner } from "react-icons/fa";
import { getAuthUser } from "../../api/request";
import { getUserInfo } from "../../api/request";

const { useEffect } = React;

export default function HubAppRedirect() {
  const history = useHistory();

  const url = new URL(window.location.href);

  const userId = url.searchParams.get("userId");

  const accessToken = url.searchParams.get("accessToken");

  const setAuthUser = useStoreActions(
    (actions) => actions.authStore.setAuthUser
  );

  const initDetails = async () => {
    localStorage.clear();
    if (accessToken) {
      setUserToken(accessToken, () => {});

      try {
        const authUser = await getUserInfo(accessToken);

        setAuthUser(authUser);
      } catch (error) {
        console.log("");
      }

      history.push(`/landingpage`, { action: "redirect" });
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    initDetails();
  }, [userId]);

  return (
    <div
      className={`w-full h-screen flex flex-col justify-center items-center`}
    >
      <FaSpinner className={`animate-spin text-qipGreen font-bold text-6xl`} />

      <div>Please wait...</div>
    </div>
  );
}
