import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";
import { IServiceDetails } from "../../types/services";
import { getUserDataServices } from "../../api/request";
import { userServicesAudit } from "../../helpers/userServicesAudit";

export interface UserModel {
  servicesDetails?: IServiceDetails[] | [];
  fetchServicesDetails: Thunk<UserModel, number>;
  setServicesDetails: Action<UserModel, IServiceDetails[]>;
}

const userStore: UserModel = {
  fetchServicesDetails: thunk(async (actions, payload) => {
    const response = await getUserDataServices(payload);

    if (response.status) {
      const serviceDetails: IServiceDetails[] | [] = userServicesAudit(
        response.centers,
        response.centersData,
        response.subData
      );

      actions.setServicesDetails(serviceDetails);
    }
  }),
  setServicesDetails: action((state, payload) => {
    state.servicesDetails = payload;
  }),
};

export default userStore;
