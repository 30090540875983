import { useQuery } from "react-query";
import { useStoreState } from ".";
import { invoiceApi } from "../api/invoice";
import { STALE_TIME } from "../services/query";

export default function useFetchPastPayments(enabled = true) {
  const userInfo = useStoreState((state) => state.authStore.userInfo);

  return useQuery(["pastPayments"], () => invoiceApi.get(userInfo?.id), {
    enabled: enabled && !!userInfo?.id,
    staleTime: STALE_TIME.HIGH,
  });
}
