import { IServiceDetails } from "../types/services";
import { isEmpty } from "lodash";
import moment from "moment";

/***
 *
 * Conditions for Trial
 * Can still launch qip or portal - if no sub, diffTIme > 0
 * Can not launch qip or portal - if no sub, diffTime <= 0
 * Show Trial Remaining Banner - if no sub, diffTime <= 5 and diffTime > 0
 * Redirect onlogin to trial page - if no sub, diffTime <= 0
 *
 * Conditions for Stripe Subscription
 * Can still launch qip or portal - if has sub, diff is less 7 days and failedPayment
 * Can not launch qip or portal - if has sub diff is more 7 days and failedPayment
 * Show Payment Failed banner- if has sub, diff is > -30 and diff is <= 0 and failedPayment
 *
 */

export const userServicesAudit = (
  centers: any,
  centersData: any,
  subData: any
): IServiceDetails[] => {
  console.log("sub Data", subData);

  return centers.map((data: any) => {
    const datetime_end_sub_unix = subData.products
      ? subData.products.some(
          (product: any) => Number(product.center_id) === Number(data.id)
        )
        ? subData.products.filter(
            (p: any) => Number(p.center_id) === Number(data.id)
          )[0].subscription.current_period_end
        : false
      : false;

    let datetime_end = "";

    if (
      datetime_end_sub_unix === undefined ||
      datetime_end_sub_unix === false
    ) {
      datetime_end = centersData.centers.filter(
        (center: any) => Number(center.center_id) === Number(data.id)
      )[0].datetime_end;
    } else {
      datetime_end = moment.unix(datetime_end_sub_unix).format("DD-MM-YYYY");
    }

    let subStatus: any = {};

    const subStatusCheck = subData.products
      ? subData.products.filter(
          (sub: any) => Number(sub.center_id) === Number(data.id)
        )
        ? subData.products.filter(
            (sub: any) => Number(sub.center_id) === Number(data.id)
          )[0]
        : {}
      : {};

    if (subStatusCheck !== undefined) {
      subStatus = subStatusCheck;
    }

    console.log("substatus ", subStatusCheck);
    /*
     *https://stripe.com/docs/billing/subscriptions/overview
     */
    const failedPayment = !isEmpty(subStatus)
      ? subStatus.subscription.status === "incomplete_expired" ||
        subStatus.subscription.status === "incomplete" ||
        subStatus.subscription.status === "past_due" ||
        subStatus.subscription.status === "unpaid"
      : false;

    const dt1 = datetime_end.split("-");
    //date string: 26-04-2022

    const year1 = Number(dt1[2]);
    const month1 = Number(dt1[1].replace(/^0+/, ""));
    const day1 = Number(dt1[0].replace(/^0+/, ""));

    const date1 = new Date(year1, month1 - 1, day1, 0, 0, 0);

    const date2 = new Date();
    const diffTime = Number(date1) - Number(date2);

    const dayDiffTime = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    console.log("dayDiff ", dayDiffTime);
    /**
     * Test by Induction
     */
    // const dayDiffTime = 1;
    // subStatus = {};

    const trialRemainingBanner = {
      show: dayDiffTime <= 5 && dayDiffTime > 0 && isEmpty(subStatus),
      remaining: dayDiffTime,
    };

    const trialEnded = isEmpty(subStatus) && dayDiffTime <= 0;

    const canStillAccessTrial = isEmpty(subStatus) && dayDiffTime > 0;

    const getpFDayDiff = () => {
      if (failedPayment) {
        const dateUnix = subData.products.some(
          (product: any) => Number(product.center_id) === Number(data.id)
        )
          ? subData.products.filter(
              (p: any) => Number(p.center_id) === Number(data.id)
            )[0].subscription.current_period_start
          : -1;

        const date = moment.unix(dateUnix);
        const dateNow = moment.unix(Math.floor(Date.now() / 1000));

        const diff = Math.ceil(moment.duration(date.diff(dateNow)).asDays());

        return diff;
      } else {
        return 1000;
      }
    };

    const canStillAccessSub =
      (failedPayment && getpFDayDiff() >= -7) ||
      (!isEmpty(subStatus) &&
        (subStatus.subscription.status === "active" ||
          subStatus.subscription.status === "trialing"));

    console.log("fDayDiff ", getpFDayDiff(), " data ", data);

    const showPaymentFailedBanner =
      failedPayment && getpFDayDiff() <= 0 && getpFDayDiff() > -30;

    const redirectPaymentFailedPage =
      failedPayment && getpFDayDiff() < -7 && getpFDayDiff() >= -30;

    const redirectToPricingPlanPage =
      (failedPayment && getpFDayDiff() < -30) ||
      (!isEmpty(subStatus) && subStatus.subscription.status === "canceled");

    return {
      id: data.id,
      user_id: data.user_id,
      type: data.type,
      name: data.name,
      approvalNumber: data.approval_number,
      serviceCatId: data.service_cat_id,
      data_completion: data.data_completion,
      website_link: data.website_link,
      description: data.description,
      brand_color: data.brand_color,
      skin: data.skin,
      collection_loaded: data.collection_loaded,
      is_archive: data.is_archive,
      has_qip: data.has_qip === 1,
      has_portal: data.has_portal === 1,
      trialRemainingBanner,
      diffTime: dayDiffTime, // subscription lapsed days
      showPaymentFailedBanner, // show banner there's an error with payment...
      redirectToPricingPlanPage,
      // redirect to payment field page
      redirectPaymentFailedPage,
      hasSub: !isEmpty(subStatus), // trial or not
      trialEnded,
      failedPayment,
      datetime_end,
      canAccessQIP:
        data.has_qip === 1 ? canStillAccessTrial || canStillAccessSub : false,
      canAccessPortal:
        data.has_portal === 1
          ? canStillAccessTrial || canStillAccessSub
          : false,
      subStatus: !isEmpty(subStatus) ? subStatus.subscription.status : "noStat",
      subscription: !isEmpty(subStatus)
        ? subData.products.filter(
            (sub: any) => Number(sub.center_id) === Number(data.id)
          )[0]
        : [],
      isOnTrial: !isEmpty(subStatus)
        ? subStatus.subscription.status === "trialing"
        : false,
    };
  }) as IServiceDetails[];
};
