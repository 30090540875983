import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";

interface IAction {
  doAction: boolean;
  reference: string;
}

export interface AppModel {
  showAction: IAction;
  setShowAction: Action<AppModel, IAction>;
}

const appStore: AppModel = {
  showAction: {
    doAction: false,
    reference: "",
  },
  setShowAction: action((state, payload) => {
    state.showAction = payload;
  }),
};

export default appStore;
