import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";

const Plans = lazy(() => import("../../pages/Plans"));

interface PlansProps {
  route: typeof Routes.PLANS;
  path: string;
  exact: boolean;
}

const PlansRoute: React.FC<PlansProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute path={path} route={route} exact={exact} Component={Plans} />
  );
};

export default PlansRoute;
