import React, { useEffect, Suspense } from "react";
import {
  BrowserRouter,
  Switch,
  useRouteMatch,
  Route,
  Redirect,
} from "react-router-dom";
import Layout from "../../components/Layout";
import { AppContext, Routes } from "../../context/App";
import { useStoreState, useStoreActions } from "../../hooks";
import HomePage from "../../pages/HomePage";
import { SUB_PAGE } from "../../pages/Payment";
import { TABS as SUBSCRIPTION_TABS } from "../../pages/Subscription";
import HelpPageRoute from "../HelpPage";

import HomePageRoute from "../HomePage";
import LandingPageRoute from "../LandingPage";
import PaymentRoute from "../PaymentPage";
import PlansRoute from "../Plans";
import SubscriptionRoute from "../Subscription";
import ThankYouRoute from "../ThankYou";
import TrialRoute from "../Trial";
import UserAccountsRoute from "../UserAccounts";
import ResubscribeRoute from "../Resubscribe";

const LoggedInPages: React.FC<any> = (): JSX.Element => {
  const match = useRouteMatch();

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <AppContext.Provider
        value={{ route: Routes.HOMEPAGE, authed: true, showMenu: true }}
      >
        <Switch>
          <HomePageRoute
            path={`/homepage`}
            exact={true}
            route={Routes.HOMEPAGE}
          />
          <LandingPageRoute
            path={`/landingpage`}
            exact={true}
            route={Routes.LANDINGPAGE}
          />
          <UserAccountsRoute
            path={`/user-accounts`}
            exact={true}
            route={Routes.USERACCOUNTS}
          />

          <SubscriptionRoute
            path={`/subscription`}
            exact={true}
            route={Routes.SUBSCRIPTIONS}
          />

          <SubscriptionRoute
            path={`/subscription/:tab(${Object.values(SUBSCRIPTION_TABS).join(
              "|"
            )})`}
            exact={false}
            route={Routes.SUBSCRIPTIONS}
          />

          <ThankYouRoute
            path={`/thank-you`}
            exact={true}
            route={Routes.THANKYOU}
          />

          <PaymentRoute path={`/payment`} exact={true} route={Routes.PAYMENT} />
          <PaymentRoute
            path={`/payment/:page(${Object.values(SUB_PAGE).join("|")})`}
            exact={false}
            route={Routes.PAYMENT}
          />

          <PlansRoute path={`/plans`} exact={true} route={Routes.PLANS} />

          <TrialRoute path={`/trial`} exact={true} route={Routes.TRIAL} />

          <ResubscribeRoute
            path={`/resubscribe`}
            exact={true}
            route={Routes.RESUBSCRIBE}
          />

          <HelpPageRoute path={`/help`} exact={true} route={Routes.HELPPAGE} />

          <Route
            exact
            path={`/`}
            render={(props) => {
              return (
                <Layout
                  route={Routes.HOMEPAGE}
                  Children={HomePage}
                  {...props}
                />
              );
            }}
          />

          <Route
            path={`${match.path}`}
            render={() => {
              return <Redirect to={`/`} />;
            }}
          />
        </Switch>
      </AppContext.Provider>
    </BrowserRouter>
  );
};

export default LoggedInPages;
