import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";
import { useStoreActions, useStoreState } from "../../hooks";

const HomePage = lazy(() => import("../../pages/HomePage"));

interface HomePageProps {
  route: typeof Routes.HOMEPAGE;
  path: string;
  exact: boolean;
}

const HomePageRoute: React.FC<HomePageProps> = (props) => {
  const { route, path, exact } = props;

  return (
    <PrivateRoute
      path={path}
      route={route}
      exact={exact}
      Component={HomePage}
    />
  );
};

export default HomePageRoute;
