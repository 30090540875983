import { Link, useHistory, useLocation } from "react-router-dom";
import "./NewService.css";
import PageBanner from "../PageBanner/PageBanner";
import { paymentMethodApi } from "../../../api/paymentMethod";
import { getUserLoggedIn } from "../../../services/auth";
import { createNewService } from "../../../api/request";
import { Dropdown } from "semantic-ui-react";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { LANDINGPAGE } from "../../../enum/action";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const serviceCat = [
  { text: "Long Daycare", value: 1, key: "1" },
  { text: "Family Daycare", value: 2, key: "2" },
  { text: "Pre-school", value: 3, key: "3" },
  { text: "OOSH", value: 4, key: "4" },
];

const NewService = () => {
  const query = useQuery();
  // TODO: replace with actual query params from wordpress
  // TODO: form state

  const history = useHistory();
  const location: any = useLocation();
  const product = query.get("product");
  const mentoring = query.get("mentoring");
  const isMonthly = query.get("monthly");
  const authUser = getUserLoggedIn();

  const [serviceCategory, setServiceCategory] = useState(1);
  const [serviceName, setServiceName] = useState("");
  const [approvalNumber, setApprovalNumber] = useState("");

  const [loading, setLoading] = useState(false);

  console.log({ product, mentoring, isMonthly });

  const calcPrice = (
    withMentoring: boolean,
    isMonthly: boolean,
    key: string
  ): number => {
    if (isMonthly) {
      switch (key) {
        case "qip":
          return withMentoring ? 197 : 97;
        case "portal":
          return withMentoring ? 197 : 97;
        case "portalAndQip":
          return withMentoring ? 294 : 194;
        default:
          return 997;
      }
    } else {
      switch (key) {
        case "qip":
          return withMentoring ? 1997 : 997;
        case "portal":
          return withMentoring ? 1997 : 997;
        case "portalAndQip":
          return withMentoring ? 2994 : 1994;
        default:
          return 9970;
      }
    }
  };

  const getProductInfo = () => {
    if (location.state !== undefined) {
      const { state } = location;
      return {
        name: state.product.title,
        action: state.action,
        key: state.product.key,
        frequency: state.isMonthly ? "monthly" : "yearly",
        isMonthly: state.isMonthly,
        withMentoring: state.includeMentoring,
        price: state.isMonthly ? state.product.monthly : state.product.yearly,
        price_id: state.priceId,
        trialEnded: state.trialEnded,
      };
    } else {
      return {
        name: "",
        action: "",
        key: "",
        frequency: "",
        isMonthly: false,
        withMentoring: false,
        price: "",
        price_id: "",
        trialEnded: false,
      };
    }
  };

  const onAddService = async () => {
    if (!serviceName) {
      return;
    }

    try {
      setLoading(true);
      const response = await paymentMethodApi.get(authUser.id);
      const pmId = response[0].id;

      const response1 = await createNewService({
        user_id: authUser.id,
        service_name: serviceName,
        service_app_num: approvalNumber,
        service_cat: serviceCategory,
        center_user_type: "admin",
        name: authUser.name,
        email: authUser.email,
        phone: authUser.phone,
        created_at: "",
        price: getProductInfo().price_id,
        plan: getProductInfo().name,
        payment_method_id: pmId,
      });

      history.push(`/landingpage`, {
        action: LANDINGPAGE.new_service,
        serviceName: serviceName,
      });

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <PageBanner title="Add your new service" />
      <div className="signUp container">
        <div className="mainContent">
          <div className="signUpContainer">
            <div className="signUpText">
              <h2>Let’s add your new service.</h2>
              <p>
                {`To add a new service to your current subscription we first we need some information to get setup and to complete your ${calcPrice(
                  getProductInfo().withMentoring,
                  getProductInfo().isMonthly,
                  getProductInfo().key
                )}/${getProductInfo().frequency} subscription to a ${
                  getProductInfo().name
                } plan.`}
              </p>
            </div>
            <div className="signUpInput">
              <div className="inputRow">
                <div className="inputCol">
                  <label>
                    Service name <span className="required">*</span>
                  </label>
                  <input
                    onChange={(e) => setServiceName(e.target.value)}
                    type="text"
                    placeholder="Service name"
                  />
                </div>
                <div className="inputCol">
                  <label>Service approval number</label>
                  <input onChange={(e) => setApprovalNumber(e.target.value)} />
                </div>
              </div>
              <div className="inputRow">
                <div className="inputCol">
                  <label>
                    Service type <span className="required">*</span>
                  </label>
                  <select
                    className={`dropdownBox`}
                    onChange={(e) => setServiceCategory(Number(e.target.value))}
                  >
                    {serviceCat.map((cat) => {
                      return <option value={cat.value}>{cat.text}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="inputBtn">
                <button
                  disabled={loading}
                  onClick={onAddService}
                  className="btnBlue"
                >
                  {loading ? (
                    <div className={`flex justify-center items-center`}>
                      <FaSpinner className={`animate-spin`} />{" "}
                      <span className={`ml-2`}>Please wait...</span>
                    </div>
                  ) : (
                    `ADD TO YOUR SUBSCRIPTION`
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewService;
