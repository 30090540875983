import { useHistory, useLocation } from "react-router-dom";
import page_banner_img from "../../static/images/page_banner_img.jpg";
import "./index.css";
import PricingTable from "./PricingTable";
import { useStoreActions, useStoreState } from "../../hooks";
import { getUserLoggedIn, isUserLoggedIn } from "../../services/auth";
import { changePlan } from "../../api/request";
import { useState } from "react";
import Confirm from "../../components/Popups/Confirm";
import { FaSpinner } from "react-icons/fa";
import { LANDINGPAGE } from "../../enum/action";

const NO_ACTIVE_PLAN = () => {
  return {
    TITLE: "Sign up for a plan",
    BANNER: "*Get 2 months FREE with an annual plan",
    DESC: "Choose a plan that’s right for you, or visit Holistic Management for more details about our plans.",
  };
};

const CHANGE_PLAN = (serviceName?: string) => {
  return {
    TITLE: `Change your plan for ${serviceName}`,
    BANNER: "*Benefit from a discount with an annual plan",
    DESC: `You can change your product plan by choosing a new product from our available plans.
  Downgraded plans will update at the start of your next billing period.
  Upgraded plans will take immediate effect.`,
  };
};

const ADD_NEW_SERVICE = {
  TITLE: "Add a new service",
  BANNER: "*Benefit from a discount with an annual plan",
  DESC: "Extend your current subscription by adding another of your earlychildcare services to your current Holistic Management subscription.",
};

const Plans = () => {
  const location: any = useLocation();

  const history = useHistory();

  const services = useStoreState((state) => state.userStore.servicesDetails);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [currentPriceId, setCurrentPriceId] = useState("");

  const fetchServicesDetails = useStoreActions(
    (actions) => actions.userStore.fetchServicesDetails
  );

  // const noActivePlan = !(services && services?.length > 0);

  const noActivePlan =
    location.state !== undefined
      ? location.state.action === "add_service"
        ? true
        : location.state.action === "change_plan"
        ? false
        : true
      : true;

  const authUser = getUserLoggedIn();

  const [loading, setLoading] = useState(false);

  // const banner = noActivePlan ? NO_ACTIVE_PLAN.BANNER : CHANGE_PLAN.BANNER;

  const getRequest = () => {
    if (location.state !== undefined) {
      if (location.state.action === LANDINGPAGE.change_plan) {
        return {
          ...CHANGE_PLAN(location.state.serviceName),
          centerId: location.state.centerId,
          serviceName: location.state.serviceName,
          interval: location.state.interval,
        };
      }
      if (location.state.action === LANDINGPAGE.new_service) {
        return {
          ...ADD_NEW_SERVICE,
          centerId: null,
          serviceName: "",
          interval: null,
        };
      }
    }
    return {
      ...NO_ACTIVE_PLAN(),
      centerId: null,
      serviceName: "",
      interval: null,
    };
  };

  const serviceChangePlan = async () => {
    try {
      setLoading(true);
      const response = await changePlan(
        authUser.id,
        getRequest().centerId,
        currentPriceId
      );

      setShowConfirmModal(false);

      history.push(`/landingpage`, {
        action: LANDINGPAGE.change_plan,
        trialEnded: false,
        serviceName: getRequest().serviceName,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="plans">
      {console.log("services ", services)}
      {showConfirmModal && (
        <Confirm
          isOpen={showConfirmModal}
          text={
            !loading
              ? `Are you sure you want to change your current plan for ${location.state.serviceName}? `
              : undefined
          }
          confirmText={!loading ? `CHANGE PLAN` : undefined}
          declineText={!loading ? `GO BACK` : undefined}
          onClose={!loading ? () => setShowConfirmModal(false) : undefined}
          onConfirm={() => serviceChangePlan()}
          onDecline={!loading ? () => setShowConfirmModal(false) : undefined}
          children={
            loading ? (
              <div
                className={`flex flex-col justify-center items-center py-5 px-20`}
              >
                <FaSpinner className={`animate-spin text-6xl text-qipGreen`} />{" "}
                <span className={`mt-4 text-xl`}>Please wait...</span>
              </div>
            ) : (
              <> </>
            )
          }
        />
      )}
      <div
        className="pageBanner"
        style={{ backgroundImage: `url(${page_banner_img})` }}
      >
        <h1 className="titleBanner">
          {/* {noActivePlan ? NO_ACTIVE_PLAN.TITLE : CHANGE_PLAN.TITLE} */}
          {getRequest().TITLE}
        </h1>
      </div>
      <div className="my-2 mx-10 mb-20">
        <div className="flex items-center flex-col">
          <h2 className="md:max-w-3xl p-10 text-center">
            {/* {noActivePlan ? NO_ACTIVE_PLAN.DESC : CHANGE_PLAN.DESC} */}
            {getRequest().DESC}
          </h2>
        </div>
        <PricingTable
          currentPlan={
            location.state !== undefined ? location.state.productName : ""
          }
          intervalForChangePlan={getRequest().interval}
          noActivePlan={noActivePlan}
          banner={getRequest().BANNER}
          showDiscountInfo={!noActivePlan}
          showTrialInfo={noActivePlan}
          {...(noActivePlan
            ? {
                onSignUp: (details) => {
                  console.log({ details });
                  history.push(`/payment/new-service`, {
                    ...details,
                    action: LANDINGPAGE.new_service,
                    trialEnded: false,
                  });
                },
              }
            : {
                onChangePlan: (details) => {
                  console.log({ details });
                  setCurrentPriceId(details.priceId);
                  setShowConfirmModal(true);
                },
              })}
        />
      </div>
    </div>
  );
};

export default Plans;
