import { createContext, useContext } from "react";

export enum Routes {
  HOMEPAGE = "homepage",
  LANDINGPAGE = "landingpage",
  USERACCOUNTS = "useraccounts",
  HELPPAGE = "helppage",
  LOGINPAGE = "loginpage",
  FORGOTPASSWORDPAGE = "forgotpasswordpage",
  NEWPASSWORDPAGE = "newpasswordpage",
  SUBSCRIPTIONS = "subscriptions",
  PLANS = "plans",
  PAYMENT = "payment",
  THANKYOU = "thankyou",
  TRIAL = "trial",
  RESUBSCRIBE = "resubscribe",
}

export type AppContextType = {
  route: Routes;
  authed: boolean;
  showMenu?: boolean;
};

export const AppContext = createContext<AppContextType>({
  route: Routes.HOMEPAGE,
  authed: false,
  showMenu: false,
});

export const useApp = () => useContext(AppContext);
