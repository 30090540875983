import { IAuthUser } from "../types";

export const setUserToken = (token: string, callback: any) => {
  localStorage.setItem("hubAppToken", token);
  callback();
};

export const isUserLoggedIn = () => localStorage.getItem("hubAppToken");

export const getUserLoggedIn = (): IAuthUser => {
  const currentAuthUser = localStorage.getItem("authUser") || "{}";
  return JSON.parse(currentAuthUser);
};

export const setHubViewer = (viewer: IAuthUser) => {
  localStorage.setItem("hubViewer", JSON.stringify(viewer));
};

export const getHubViewer = (): IAuthUser => {
  const viewer = localStorage.getItem("hubViewer") || "{}";
  return JSON.parse(viewer);
};

export const userLogout = (callback: any) => {
  localStorage.clear();
  localStorage.removeItem("hubAppToken");

  callback();
};
