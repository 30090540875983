/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import {
	userLogout,
	isUserLoggedIn,
	getUserLoggedIn,
} from '../../services/auth';
import { redirect } from '../../helpers/redirect';
import { useStoreState } from '../../hooks';
import { LANDINGPAGE } from '../../enum/action';

interface IFooter {
	hideMenus: boolean;
}

const Footer: React.FC<IFooter> = (props): JSX.Element => {
	const { hideMenus } = props;
	const history = useHistory();
	const [selectedService, setSelectedService] = useState<any>({});

	const hide = hideMenus && isUserLoggedIn();

	const authUser = getUserLoggedIn();

	const services =
		useStoreState(state => state.userStore.servicesDetails) || [];
	const userData = useStoreState((state: any) => state?.authStore);
	const action = useStoreState(state => state.appStore.showAction);

	useEffect(() => {
		const filterService = services.filter(
			(service: any) => service.id === userData?.centerId?.id
		);
		setSelectedService(filterService[0]);
	}, [userData?.centerId?.id]);

	const onRedirect = (app: string) => {
		redirect(app, authUser.id, userData?.centerId?.id, authUser.stripe_id);
	};

	return (
		<footer className="footer">
			<div
				className={`footerTop  ${
					isUserLoggedIn()
						? selectedService?.trialEnded ||
						  (action.doAction &&
								action.reference === LANDINGPAGE.new_signup)
							? 'hidden'
							: hide
							? 'hidden'
							: ''
						: 'hidden'
				} `}
			>
				<div className="footerRow">
					<h3>Holistic products shortcuts</h3>

					<ul className="products">
						<li className="product commingSoon">
							<svg
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="times"
								className="svg-inline--fa fa-times fa-w-11"
								role="img"
								width=" 14"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 352 512"
							>
								<path
									fill="currentColor"
									d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
								></path>
							</svg>
							<span>CCS Software</span>
							<a href="javascript:void(0)" target="_blank">
								COMING SOON
							</a>
						</li>

						<li className="product visit">
							<FaCheck size={14} />
							<span>Products & Events</span>
							<a
								rel="noreferrer"
								href="https://holisticmanagement.net.au/resources/ "
								target="_blank"
							>
								VISIT
							</a>
						</li>

						<li className="product commingSoon">
							<svg
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="times"
								className="svg-inline--fa fa-times fa-w-11"
								role="img"
								width=" 14"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 352 512"
							>
								<path
									fill="currentColor"
									d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
								></path>
							</svg>
							<span>HR Software</span>
							<a href="javascript:void(0)" target="_blank">
								COMING SOON
							</a>
						</li>

						<li className="product commingSoon">
							<FaTimes size={20} />
							<span>Resources</span>
							<a href="javascript:void(0)" target="_blank">
								COMING SOON
							</a>
						</li>

						<li className="product visit">
							<svg
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="check"
								className="svg-inline--fa fa-check fa-w-16"
								role="img"
								width=" 14"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
							>
								<path
									fill="currentColor"
									d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
								></path>
							</svg>
							<span>HMC Portal</span>
							<a
								className={`cursor-pointer access-${selectedService?.has_portal
									? 'visit'
									: selectedService?.isOnTrial
									? 'trail'
									: 'sign-up'}`}
								onClick={() => {
									if (
										selectedService?.canAccessPortal ||
										selectedService?.isOnTrial
									) {
										onRedirect('portal');
									} else {
										history.push(`/plans`);
									}
								}}
							>
								{selectedService?.has_portal
									? 'Visit'
									: selectedService?.isOnTrial
									? 'TRIAL'
									: 'SIGN UP'}
							</a>
						</li>

						<li className="product visit">
							<svg
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="check"
								className="svg-inline--fa fa-check fa-w-16"
								role="img"
								width=" 14"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
							>
								<path
									fill="currentColor"
									d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
								></path>
							</svg>
							<span>Holistic QIP</span>
							<a
								className={`cursor-pointer access-${selectedService?.has_qip
									? 'visit'
									: selectedService?.isOnTrial
									? 'trail'
									: 'sign-up'}`}
								onClick={() => {
									if (
										selectedService?.canAccessQIP ||
										selectedService?.isOnTrial
									) {
										onRedirect('qip');
									} else {
										history.push(`/plans`);
									}
								}}
							>
								{selectedService?.has_qip
									? 'Visit'
									: selectedService?.isOnTrial
									? 'TRIAL'
									: 'SIGN UP'}
							</a>
						</li>
					</ul>
				</div>
			</div>

			<div className="footerBottom">
				<div className="footerRow">
					<div
						className={`footerMenu ${
							isUserLoggedIn()
								? selectedService?.trialEnded ||
								  (action.doAction &&
										action.reference ===
											LANDINGPAGE.new_signup)
									? 'invisible'
									: hide
									? 'invisible'
									: 'visible'
								: 'invisible'
						}`}
					>
						<ul>
							<li>
								<Link className="footerMenuText" to="/">
									Dashboard
								</Link>
							</li>

							<li>
								<Link
									className="footerMenuText"
									to="/user-accounts"
								>
									Users
								</Link>
							</li>

							<li>
								<a
									href="https://holisticmanagement.net.au/get-help/"
									className="footerMenuText"
									target="_blank"
									rel="noreferrer"
								>
									Help
								</a>
							</li>
							<li>
								<a
									href="#"
									className="footerMenuText"
									onClick={e => {
										e.preventDefault();
										userLogout(window.location.reload());
									}}
								>
									Logout
								</a>
							</li>
						</ul>
					</div>

					<div className="footerCopyright">
						<span>
							©️2021 - Holistic Management & Consultancy | All
							rights reserved
						</span>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
