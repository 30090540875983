import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import styles from "./Pagination.module.css";

export interface PaginationProps {
  onClickLeft?: () => void;
  onClickRight?: () => void;
  onClickPage: (index: number) => void;
  pageCount: number;
  activePage: number;
}

const Pagination = (props: PaginationProps) => {
  return (
    <div className={`${styles.pagination}`}>
      {props.onClickLeft && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.onClickLeft?.();
          }}
        >
          <FaAngleLeft size={16} />
        </a>
      )}
      {Array(props.pageCount)
        .fill("")
        .map((x, i) => (
          <a
            key={i}
            href="#"
            className={i + 1 === props.activePage ? styles.active : ""}
            onClick={(e) => {
              e.preventDefault();
              props.onClickPage(i);
            }}
          >
            {++i}
          </a>
        ))}
      {props.onClickRight && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.onClickRight?.();
          }}
        >
          <FaAngleRight size={16} />
        </a>
      )}
    </div>
  );
};

export default Pagination;
