import React from "react";
import { Redirect, Route } from "react-router-dom";
import Layout from "../Layout";
import { Routes } from "../../context/App";
import { useStoreActions, useStoreState } from "../../hooks";

interface RouterProps {
  Component: React.ElementType;
  exact: boolean;
  route:
    | typeof Routes.HOMEPAGE
    | typeof Routes.LANDINGPAGE
    | typeof Routes.USERACCOUNTS
    | typeof Routes.HELPPAGE
    | typeof Routes.LOGINPAGE
    | typeof Routes.FORGOTPASSWORDPAGE
    | typeof Routes.NEWPASSWORDPAGE
    | typeof Routes.SUBSCRIPTIONS
    | typeof Routes.PLANS
    | typeof Routes.PAYMENT
    | typeof Routes.THANKYOU
    | typeof Routes.TRIAL
    | typeof Routes.RESUBSCRIBE;
  path: string;
}

const PrivateRoute: React.FC<RouterProps> = ({
  Component,
  exact,
  route,
  path,
}: RouterProps): JSX.Element => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        return <Layout route={route} Children={Component} {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
