import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";
import HelpThankYou from "../../components/HelpThankyou";

const NewPasswordPage = lazy(() => import("../../pages/NewPassword"));

interface NewPasswordPageProps {
  route: typeof Routes.NEWPASSWORDPAGE;
  path: string;
  exact: boolean;
}

const NewPasswordPageRoute: React.FC<NewPasswordPageProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute
      path={path}
      route={route}
      exact={exact}
      Component={NewPasswordPage}
    />
  );
};

export default NewPasswordPageRoute;
