import { useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import './center.css';

const Center = ({ center, onCenterChange }: { center: any, onCenterChange: any }) => {
    const [isChecked, setIsChecked] = useState(false);
    const userData: any = useStoreState((state: any) => state?.authStore);
    
    useEffect(() => {
        if (userData?.centerId?.id === center?.center_id) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }, [userData?.centerId, center]);

    return (
        <button
            key={center.id}
            className={"serviceButtonContainer" + ' ' + (isChecked ? 'serviceSelectedButtonContainer' : '')}
            onClick={() => {
                if (!isChecked) {
                    onCenterChange(center?.center_id);
                }
            }}
        >            
            <div className={`flex justify-start items-center ${center?.center_id}`} >
                <div
                    className={`${
                        userData?.centerId?.id === center?.center_id
                            ? "serviceCheck"
                            : "serviceNotCheck"
                    } flex justify-center items-center`}
                >
                    <FaCheck />
                </div>
                <span className={`ml-4`}>
                    {' '}
                    {center.center_name}
                </span>
            </div>
        </button>
        
    )
}

export default Center;