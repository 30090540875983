/***
 * RoutingScheme
 * __crypto.test.tsx__
 */

import { generateCipherText, generateHash } from "../services/crypto";
import * as Crypto from "crypto-js";
import { RoutingEncryption } from "../contracts/RoutingEncryption";

export class RoutingScheme implements RoutingEncryption {
  private message: string = "";
  private InitializationVector: Crypto.lib.WordArray =
    Crypto.lib.WordArray.random(16);
  private Salt: Crypto.lib.WordArray = Crypto.lib.WordArray.random(16);

  private CipherText: string = "";

  /**
   * @param message
   */
  constructor(message: string) {
    this.message = message;
  }

  /**
   *
   * @param generate
   * @returns
   */
  generateIV(generate: boolean = true) {
    const iv = Crypto.lib.WordArray.random(16);
    this.InitializationVector = iv;
    return this;
  }

  /**
   *
   * @param generate
   * @returns
   */
  generateSalt(generate: boolean = true) {
    const salt = Crypto.lib.WordArray.random(16);
    this.Salt = salt;
    return this;
  }

  /**
   *
   * @returns
   */

  getIV() {
    return this.InitializationVector;
  }

  /**
   *
   * @returns
   *
   */
  getSalt() {
    return this.Salt;
  }

  /**
   * Generate a 128 bit Cipher | 10 rounds
   */
  generateCipher() {
    return generateCipherText(
      this.message,
      this.InitializationVector,
      this.Salt
    );
  }

  /**
   *
   * @param message
   * @returns
   */
  generateSignature(message: string) {
    return generateHash(message);
  }
}
