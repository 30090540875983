import React, { useState } from "react";
import IncorrectPassword from "../../components/Popups/IncorrectPassword";
import login_banner_logged_out from "../../static/images/login_banner_logged_out.jpg";
import { setUserToken } from "../../services/auth";
import "./index.css";

import { login } from "../../api/request";
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../hooks";
import MemberAccountExpired from "../../components/Popups/MemberAccountExpired/MemberAccountExpired";

const Login: React.FC<any> = (): JSX.Element => {
  const [isOpenIncorrectPass, setIsOpenIncorrectPass] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [fieldsChecked, setFieldsChecked] = useState<boolean>(false);
  const history = useHistory();

  const [accountExpiredShow, setAccountExpiredShow] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const setUserInfo = useStoreActions(
    (actions) => actions.authStore.saveUserInfo
  );

  const setAuthUser = useStoreActions(
    (actions) => actions.authStore.setAuthUser
  );

  const login = useStoreActions((actions) => actions.authStore.login);

  const onLogin = async () => {
    setFieldsChecked(true);
    if (
      !email ||
      !password! ||
      (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
    ) {
      return;
    }
    try {
      setLoading(true);

      const response = await login({
        username: email,
        password,
      });

      if (response.status) {
        if (response.redirect) {
          if (response.isMember) {
            setAccountExpiredShow(true);
            return;
          } else {
            setAuthUser(response.response.auth_user);
            setUserInfo(response.response.auth_user);
            setUserToken(response.response.access_token, () => {
              // window.location.assign(`/trial`);
              window.location.assign(response.location);
            });
          }
        } else {
          setAuthUser(response.response.auth_user);
          setUserInfo(response.response.auth_user);
          setUserToken(
            response.response.access_token,
            window.location.reload()
          );
        }
      } else {
        setIsOpenIncorrectPass(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {accountExpiredShow && (
        <MemberAccountExpired
          text={`Your administrators account has expired. Please contact your childcare’s service  administrator for more information.`}
          confirmText={`OK`}
          onConfirm={() => setAccountExpiredShow(false)}
          isOpen={accountExpiredShow}
          onClose={() => {
            setAccountExpiredShow(false);
          }}
        />
      )}

      <IncorrectPassword
        isOpen={isOpenIncorrectPass}
        onTryAgain={() => setIsOpenIncorrectPass(false)} //TODO: replace with actual functionality
        onGetNewPass={() => history.push("/forgot-password")}
        onClose={() => setIsOpenIncorrectPass(false)}
      />
      <div
        className="pageBanner"
        style={{ backgroundImage: `url(${login_banner_logged_out})` }}
      >
        <div className="container">
          <div className="pageBannerContent">
            <h1>Login to your Holistic HUB account</h1>
            <p>
              Login with your email address and password to access Holistic QIP
              and Holistic Management Portal.
            </p>
            <p className="mt-5">
              <span>
                Don’t have an account? Visit{" "}
                <a href={process.env.REACT_APP_HM_WP} className="underline">
                  Holistic Management
                </a>{" "}
                to signup for a free 30 day trial.
              </span>
            </p>
          </div>
          <div className="formContainer">
            <div className="form">
              <div className="InputRow">
                <div className="inputGroup">
                  <label>
                    Email address<span className="required">*</span>
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    required
                    placeholder="Email Address"
                  />
                  <div className={`text-red-400 italic py-1 px-1 h-4`}>
                    {fieldsChecked &&
                      email &&
                      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && (
                        <> Please provide a valid email address. </>
                      )}
                    {fieldsChecked && !email && <> This field is required. </>}
                  </div>
                </div>
                <div className="inputGroup">
                  <label>
                    Password<span className="required">*</span>
                  </label>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? `text` : `password`}
                    placeholder="Password"
                  />
                  <div
                    onClick={() => setShowPassword(!showPassword)}
                    className={`relative toggleShowPassword cursor-pointer pr-2`}
                  >
                    {showPassword ? (
                      <FaEyeSlash className={`text-xl`} />
                    ) : (
                      <FaEye className={`text-xl`} />
                    )}
                  </div>
                  <div className={`text-red-400 italic py-1 px-1 h-4`}>
                    {fieldsChecked && !password && (
                      <> This field is required. </>
                    )}
                  </div>
                </div>
              </div>
              <div className="InputRow">
                <div className="inputBtn">
                  <button disabled={loading} onClick={onLogin}>
                    {loading ? (
                      <div className={`flex justify-center items-center`}>
                        <FaSpinner className={`animate-spin`} />
                        <span className={`ml-2`}>Please wait...</span>
                      </div>
                    ) : (
                      `LOGIN TO HOLISTIC HUB`
                    )}
                  </button>
                </div>
                <div className="forgotPass">
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
