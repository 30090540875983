import {
  FaArrowAltCircleDown,
  FaBackward,
  FaExclamationCircle,
  FaInfoCircle,
  FaReply,
  FaRocket,
} from "react-icons/fa";
import AddService from "./AddService";
import { useStoreActions, useStoreState } from "../../hooks";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ChangePlanRequest from "../../components/Popups/ChangePlanRequest/ChangePlanRequest";
import Confirm from "../../components/Popups/Confirm";
import { useState } from "react";
import { LANDINGPAGE } from "../../enum/action";
import { get as pmGet } from "../../api/paymentMethod";
import { getUserLoggedIn } from "../../services/auth";
import { useEffect } from "react";
import { isEmpty } from "lodash";

export default function SubscriptionDetails() {
  const history = useHistory();

  const services = useStoreState((state) => state.userStore.servicesDetails);

  const [showChangePlanRequest, setShowChangePlanRequest] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [gettingPMLoading, setGettingPMLoading] = useState(false);

  const [hasPm, setHasPm] = useState(false);

  const formatPrice = (amount: number, interval: string, currency?: string) => {
    const undecimated = amount / 100;
    return `$${undecimated.toLocaleString()} / ${interval}`;
  };

  const authUser = getUserLoggedIn();

  const isNotTrial = !(services?.length === 1 && !services[0].hasSub);

  useEffect(() => {
    const fetchPM = async () => {
      setGettingPMLoading(true);
      const response = await pmGet(authUser.id);
      setHasPm(!isEmpty(response));
      setGettingPMLoading(false);
    };
    fetchPM();
  }, []);

  return (
    <>
      <div className="paymentDetailsContainer">
        <Confirm
          isOpen={showConfirm}
          text={`Thank you! Your message has been sent, and we’ll be in touch soon.`}
          confirmText={`CLOSE`}
          onConfirm={() => setShowConfirm(false)}
        />
        <ChangePlanRequest
          isOpen={showChangePlanRequest}
          onClose={() => setShowChangePlanRequest(false)}
          onConfirm={() => {
            setShowChangePlanRequest(false);
            setShowConfirm(true);
          }}
        />
        <div className="paymentDetailsLeftContent">
          <h2>Subscription details</h2>
          <p>
            View all of your Holistic Manangement product subscriptions. From
            this page you can change your plan, or add additional services to
            your subscription.
          </p>
          {/* <ul className="allProducts">
            <li>All products</li>
            <li>Holistic QIP</li>
            <li>Holistic Portal</li>
          </ul> */}
        </div>
        <div className="paymentDetailsRightContent">
          {services?.map((service) => {
            return (
              <div className="service">
                {console.log("service ", service)}
                <h2>{service.name}</h2>
                <div className="serviceTable">
                  <div className="serviceTableHeader">
                    <ul className="tableListCol">
                      <li>Product</li>
                      <li>Amount</li>
                      <li>{!isNotTrial ? `Trial Ends` : `Next payment`}</li>
                    </ul>
                  </div>
                  <div className="serviceTableBody">
                    <ul className="tableListCol">
                      <li>
                        {service.hasSub
                          ? service.subscription.product
                          : "Trial"}
                      </li>
                      <li className={``}>
                        {service.hasSub
                          ? formatPrice(
                              service.subscription.subscription.plan.amount,
                              service.subscription.subscription.plan.interval
                            )
                          : ""}

                        {/* <span className="paymentDetails">Payment details</span> */}
                      </li>
                      <li className="">
                        {moment(service.datetime_end, "DD-MM-YYYY").format(
                          "DD/MM/YYYY"
                        )}
                        {/* <span className="paymentFailedText">
                          Payment failed
                        </span> */}
                      </li>
                      <li
                        className={`${
                          service.hasSub &&
                          !service.failedPayment &&
                          service.subStatus !== "canceled"
                            ? ""
                            : "hidden"
                        }`}
                      >
                        <div className="paymentFrequency">
                          <a
                            onClick={() => {
                              if (
                                service.hasSub &&
                                hasPm &&
                                (service.subStatus === "active" ||
                                  service.subStatus === "trialing")
                              ) {
                                history.push(`/plans`, {
                                  action: LANDINGPAGE.change_plan,
                                  centerId: service.id,
                                  serviceName: service.name,
                                  productName: service.hasSub
                                    ? service.subscription.product
                                    : "",
                                  interval:
                                    service.subscription.subscription.plan
                                      .interval,
                                });
                              }

                              if (service.hasSub && !hasPm) {
                                setShowChangePlanRequest(true);
                              }
                            }}
                            className="upGrade"
                          >
                            <FaRocket size={13} />
                            CHANGE PLAN
                          </a>
                        </div>
                      </li>

                      <li
                        className={`${
                          service.hasSub &&
                          // service.diffTime < 0 &&
                          // service.diffTime >= -30 &&
                          service.failedPayment
                            ? ""
                            : "hidden"
                        }`}
                      >
                        <div className="paymentFrequency">
                          <a
                            onClick={() => {
                              history.push(`/payment/failed`);
                            }}
                            className="downGrade"
                          >
                            <FaExclamationCircle size={13} />
                            PAYMENT&nbsp;FAILED
                          </a>
                        </div>
                      </li>

                      <li
                        className={`${
                          service.hasSub && service.redirectToPricingPlanPage
                            ? ""
                            : "hidden"
                        }`}
                      >
                        <div className="paymentFrequency">
                          <a
                            onClick={() => {
                              history.push(`/plans`);
                            }}
                            className="resubscribe"
                          >
                            <FaReply size={13} />
                            RESUBSCRIBE
                          </a>
                        </div>
                      </li>

                      <li className={`${!isNotTrial ? "" : "hidden"}`}>
                        <div className="paymentFrequency">
                          <a
                            onClick={() => {
                              history.push(`/trial`);
                            }}
                            className="upGrade"
                          >
                            <FaRocket size={13} />
                            SIGNUP
                          </a>
                        </div>
                      </li>
                    </ul>
                    {/* <ul className="tableListCol">
                      <li>Holistic Management Portal +Mentoring</li>
                      <li>
                        $91 / month{" "}
                        <span className="paymentDetails">Payment details</span>
                      </li>
                      <li>12/03/2021</li>
                      <li>
                        <div className="paymentFrequency">
                          <a href="#" className="downGrade">
                            <FaArrowAltCircleDown size={13} />
                            DOWNGRADE
                          </a>
                        </div>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            );
          })}

          {/* <div className="service">
            <h2>SERVICE B</h2>
            <div className="serviceTable">
              <div className="serviceTableHeader">
                <ul className="tableListCol">
                  <li>Products</li>
                  <li>Amount</li>
                  <li>Next payment</li>
                </ul>
              </div>
              <div className="serviceTableBody">
                <ul className="tableListCol">
                  <li>Holistic QIP</li>
                  <li>---</li>
                  <li>---</li>
                  <li>
                    <div className="paymentFrequency">
                      <a href="" className="upGrade">
                        <FaRocket size={13} />
                        ADD
                      </a>
                    </div>
                  </li>
                </ul>
                <ul className="tableListCol">
                  <li>Holistic Portal</li>
                  <li>
                    $91 / month{" "}
                    <span className="paymentDetails">Payment details</span>
                  </li>
                  <li>12/03/2021</li>
                  <li>
                    <div className="paymentFrequency">
                      <a href="#" className="upGrade">
                        <FaRocket size={13} />
                        UPGRADE
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {isNotTrial && <AddService hasPM={hasPm} />}
    </>
  );
}
