import { FaSpinner } from "react-icons/fa";

const BtnLoading = () => {
  return (
    <div className="flex justify-center items-center">
      <FaSpinner className="animate-spin" />
      <span className="ml-2">Please wait...</span>
    </div>
  );
};

export default BtnLoading;
