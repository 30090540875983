import { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { FaRocket, FaTimes } from "react-icons/fa";
import { paymentMethodApi } from "../../api/paymentMethod";
import AddService from "./AddService";
import Confirm from "../../components/Popups/Confirm";
import Card from "../../components/Form/Card";
import BtnLoading from "../../components/Form/BtnLoading";
import CardIcons from "./CardIcons";
import { add } from "../../api/paymentMethod";
import { useStoreState } from "../../hooks";
import useFetchPaymentMethods from "../../hooks/useFetchPaymentMethods";
import Spinner from "../../components/Spinner";

export default function PaymentDetails() {
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const [toDelete, setToDelete] = useState<string>();
  const [showSuccessAdded, setShowSuccessAdded] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [cardName, setCardName] = useState("");
  const [loadingAddPayment, setLoadingAddPayment] = useState(false);
  const userInfo = useStoreState((state) => state.authStore.userInfo);

  const { data: paymentMethods, isFetching } = useFetchPaymentMethods();
  const { isLoading: isLoadingDelete, mutate: removePM } = useMutation(
    paymentMethodApi.remove,
    {
      onSuccess: () => {
        setToDelete(undefined);
        queryClient.invalidateQueries("paymentMethods");
      },
    }
  );

  const onAddPaymentMethod = useCallback(async () => {
    if (!stripe || !elements) return;

    const card = elements.getElement(CardNumberElement);
    if (!card) return;

    setShowErrors(false);
    if (!cardName) {
      setShowErrors(true);
      return;
    }

    setLoadingAddPayment(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        name: cardName,
      },
    });

    error && setShowErrors(true);

    if (paymentMethod && userInfo) {
      const success = await add(userInfo?.id, paymentMethod.id);
      setLoadingAddPayment(false);
      queryClient.invalidateQueries("paymentMethods");

      if (success) {
        success && setShowSuccessAdded(true);
        setCardName("");
        card.clear();
        elements.getElement(CardExpiryElement)?.clear();
        elements.getElement(CardCvcElement)?.clear();
      }
    } else {
      setLoadingAddPayment(false);
    }
  }, [cardName, elements, queryClient, stripe, userInfo]);

  return (
    <>
      <Confirm
        text={`Are you sure you want to remove this payment method?`}
        isOpen={toDelete !== undefined}
        confirmText={isLoadingDelete ? undefined : "UNLINK"}
        declineText="CANCEL"
        onConfirm={() => toDelete && removePM(toDelete)}
        onClose={() => setToDelete(undefined)}
        onDecline={isLoadingDelete ? undefined : () => setToDelete(undefined)}
      >
        {isLoadingDelete && (
          <div>
            <Spinner text="Please wait..." />
          </div>
        )}
      </Confirm>
      <Confirm
        text={`New payment method is now added to Holistic Hub`}
        isOpen={showSuccessAdded}
        confirmText="OK"
        onConfirm={() => setShowSuccessAdded(false)}
        onClose={() => setShowSuccessAdded(false)}
      />
      <div className="paymentDetailsContainer">
        <div className="paymentDetailsLeftContent">
          <h2>Payment details</h2>
          <p>
            If you are paying your subscription by credit card, you can update
            or change your payment details from here.
          </p>
        </div>
        <div className="paymentDetailsRightContent">
          <div className="subscription">
            <h2>Subscription by Credit Card (Stripe)</h2>
            <div className="subscriptionTable">
              <div className="subscriptionTableHeader">
                <ul className="tableListCol">
                  <li>Method</li>
                  <li>Expires</li>
                  <li></li>
                </ul>
              </div>
              {console.log("payment methods ", paymentMethods)}
              <div className="subscriptionTableBody">
                {isFetching ? (
                  <div className="flex justify-center my-10">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {paymentMethods?.map((pm) => (
                      <ul className="tableListCol disableDelete" key={pm.id}>
                        <li>{`${
                          pm.card.brand.charAt(0).toUpperCase() +
                          pm.card.brand.slice(1)
                        } ending ${pm.card.last4}`}</li>
                        <li>{`${pm.card.exp_month}/${pm.card.exp_year}`}</li>
                        {paymentMethods.length > 1 && (
                          <li>
                            <button
                              className="removeBtn"
                              onClick={() => setToDelete(pm.id)}
                            >
                              <FaTimes size={19} />
                            </button>
                          </li>
                        )}
                      </ul>
                    ))}
                  </>
                )}
              </div>

              <div className="paymentMethod">
                <h2>Add a payment method</h2>
                <p>
                  Pay with your credit card via Stripe.
                  <span className="cardType">
                    <CardIcons />
                  </span>
                </p>
                <div className="mt-8">
                  <Card
                    cardName={cardName}
                    setCardName={setCardName}
                    showErrors={showErrors}
                  />
                  <div className="inputContainer ">
                    <div className="inputBtn">
                      <button
                        onClick={onAddPaymentMethod}
                        disabled={loadingAddPayment}
                      >
                        {loadingAddPayment ? (
                          <BtnLoading />
                        ) : (
                          <>
                            <FaRocket size={19} />
                            ADD PAYMENT METHOD
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddService />
    </>
  );
}
