import React, { useState } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { Modal } from "semantic-ui-react";
import styles from "./AddServiceRequest.module.css";
import { addServiceRequestNotification } from "../../../api/request";
import { getUserLoggedIn } from "../../../services/auth";
import { useStoreActions, useStoreState } from "../../../hooks";

interface AddServiceRequestInterface {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  className?: string;
}

const serviceCat = [
  { text: "Long Daycare", value: 1, key: "1" },
  { text: "Family Daycare", value: 2, key: "2" },
  { text: "Pre-school", value: 3, key: "3" },
  { text: "OOSH", value: 4, key: "4" },
];

const AddServiceRequest: React.FC<AddServiceRequestInterface> = (
  props
): JSX.Element => {
  const { isOpen, onClose, onConfirm, className } = props;

  const authUser = getUserLoggedIn();

  const services = useStoreState((state) => state.userStore.servicesDetails);

  const [fieldsChecked, setFieldsChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [serviceCategory, setServiceCategory] = useState(1);
  const [serviceName, setServiceName] = useState("");
  const [approvalNumber, setApprovalNumber] = useState("");
  const [preferredDate, setPreferredDate] = useState("");
  const [preferredTime, setPreferredTime] = useState("");
  const [comments, setComments] = useState("");

  const onSendRequest = async () => {
    setFieldsChecked(true);
    if (!serviceName || !preferredTime || !preferredDate) {
      return;
    }

    try {
      setLoading(true);
      const response = await addServiceRequestNotification({
        name: authUser.name,
        email: authUser.email,
        phone_number: authUser.phone || "",
        service_name: (services && services[0].name) || "",
        new_service_name: serviceName,
        service_app_num: approvalNumber || "#",
        service_type: serviceCategory,
        comments,
        daysoftheweek: preferredDate,
        timeofday: preferredTime,
      });

      if (onConfirm !== undefined) {
        onConfirm();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      centered={false}
      size={`mini`}
      open={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      closeOnDimmerClick={false}
      className={`${styles.modal}`}
    >
      <div
        className={`${styles.modalContainer}  flex flex-col justify-center items-center relative`}
      >
        <div
          onClick={() => onClose()}
          className={`${styles.close} cursor-pointer absolute top-0 right-0 flex items-center justify-center`}
        >
          <FaTimes />
        </div>
        <div
          className={`${styles.headerContainer} flex flex-col justify-center items-center`}
        >
          <h1 className={`${styles.header}`}>Add a new service request</h1>
          <h2 className={`${styles.desc}`}>
            Please complete the below form and a member of our team will be in
            touch with you about adding a new service to your existing plan.
          </h2>
        </div>
        <div className={`w-full mb-3`}>
          <h1 className={`${styles.labelComment} pb-3`}>
            New Service Name<span className={`text-red-400 `}>*</span>
          </h1>
          <input
            placeholder="Service name"
            onChange={(e) => setServiceName(e.target.value)}
            className={`${styles.serviceInput} outline-none p-3`}
          />
          <div className={`text-red-400 italic py-1 px-1 h-4`}>
            {fieldsChecked && !serviceName && <> This field is required. </>}
          </div>
        </div>
        <div className={`w-full flex justify-between items-center mb-3`}>
          <div className={`w-1/2 flex flex-col justify-center items-start`}>
            <span>Service approval number</span>
            <input
              placeholder={``}
              onChange={(e) => setApprovalNumber(e.target.value)}
              className={`${styles.dayInput} py-2 px-3  outline-none`}
            />
            <div className={`text-red-400 italic py-1 px-1 h-4`}>
              {/* {fieldsChecked && !approvalNumber && (
                <> This field is required. </>
              )} */}
            </div>
          </div>
          <div
            className={`w-1/2 flex flex-col justify-start items-center outline-none`}
          >
            <span className={`text-left w-full`}>Service type</span>
            <select
              className={`${styles.dropdownBox} w-full outline-none p-2`}
              onChange={(e) => setServiceCategory(Number(e.target.value))}
            >
              {serviceCat.map((cat) => {
                return <option value={cat.value}>{cat.text}</option>;
              })}
            </select>
            <div className={`text-red-400 italic py-1 px-1 h-4`}>
              {fieldsChecked && !serviceCategory && (
                <> This field is required. </>
              )}
            </div>
          </div>
        </div>
        <div className={`w-full`}>
          <h1 className={`${styles.labelComment} pb-3`}>Comments</h1>
          <textarea
            onChange={(e) => setComments(e.target.value)}
            className={`${styles.commentArea} outline-none p-3`}
          />
        </div>
        <div className={`w-full`}>
          <h1 className={`${styles.contactLabel} pt-4 pb-3`}>
            {`Your preferred day & time for a member of our team to contact you`}
          </h1>
          <div className={`w-full flex justify-between items-center`}>
            <div>
              <input
                placeholder={`Day`}
                onChange={(e) => setPreferredDate(e.target.value)}
                className={`${styles.dayInput} py-2 px-3  outline-none`}
              />
              <div className={`text-red-400 italic py-1 px-1 h-4`}>
                {fieldsChecked && !preferredDate && (
                  <> This field is required. </>
                )}
              </div>
            </div>
            <div>
              <input
                placeholder={`Time of day`}
                onChange={(e) => setPreferredTime(e.target.value)}
                className={`${styles.timeInput} py-2 px-3 outline-none`}
              />
              <div className={`text-red-400 italic py-1 px-1 h-4`}>
                {fieldsChecked && !preferredTime && (
                  <> This field is required. </>
                )}
              </div>
            </div>
          </div>
          <div className={`w-full flex justify-center items-center`}>
            <button
              disabled={loading}
              onClick={onSendRequest}
              className={`${styles.sendButton}  outline-none`}
            >
              {loading ? (
                <div className={`flex justify-center items-center`}>
                  <FaSpinner className={`animate-spin text-white`} />{" "}
                  <span className={`ml-2`}>Please wait...</span>
                </div>
              ) : (
                `SEND REQUEST`
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddServiceRequest;
