import React from "react";
import { FaTimes } from "react-icons/fa";
import { Modal } from "semantic-ui-react";
import styles from "./BaseModal.module.css";

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const BaseModal = (props: ModalProps) => {
  return (
    <Modal
      dimmer
      centered
      open={props.isOpen}
      onClose={props.onClose}
      closeOnDimmerClick={false}
      className={styles.baseModal}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          {props.onClose && (
            <button onClick={props.onClose}>
              <FaTimes size={16} />
            </button>
          )}
        </div>
        <>{props.children}</>
      </div>
    </Modal>
  );
};

export default BaseModal;
