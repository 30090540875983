/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
	FaCaretDown,
	FaExternalLinkSquareAlt,
	FaSignInAlt,
} from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import {
	isUserLoggedIn,
	userLogout,
	getUserLoggedIn,
} from '../../services/auth';
import { Routes } from '../../context/App';
import hub_logo from '../../static/images/hub_logo.png';

import { redirect } from '../../helpers/redirect';

import { useStoreActions, useStoreState } from '../../hooks';
import { LANDINGPAGE } from '../../enum/action';
import { isEmpty } from 'lodash';
import ChangePlanRequest from '../../components/Popups/ChangePlanRequest/ChangePlanRequest';
import Confirm from '../../components/Popups/Confirm';

import { get as pmGet } from '../../api/paymentMethod';
import ChangeCenter from './changeCenter';
import SignOut from './signOut';

interface IROUTE {
	route:
		| typeof Routes.HOMEPAGE
		| typeof Routes.LANDINGPAGE
		| typeof Routes.USERACCOUNTS
		| typeof Routes.HELPPAGE
		| typeof Routes.LOGINPAGE
		| typeof Routes.FORGOTPASSWORDPAGE
		| typeof Routes.NEWPASSWORDPAGE
		| typeof Routes.SUBSCRIPTIONS
		| typeof Routes.PLANS
		| typeof Routes.PAYMENT
		| typeof Routes.THANKYOU
		| typeof Routes.TRIAL
		| typeof Routes.RESUBSCRIBE;
	hideMenus: boolean;
}

const Header: React.FC<IROUTE> = (props): JSX.Element => {
	const { hideMenus } = props;
	const isLoggedIn = isUserLoggedIn();

	const hide = hideMenus && isLoggedIn;

	const history = useHistory();

	const authUser = getUserLoggedIn();

	const [activeNav, setActiveNav] = useState(1);
	const [selectedService, setSelectedService] = useState<any>({});

	const services =
		useStoreState(state => state.userStore.servicesDetails) || [];
	const userData = useStoreState((state: any) => state?.authStore);
	const action = useStoreState(state => state.appStore.showAction);

	useEffect(() => {
		const filterService = services.filter(
			(service: any) => service.id === userData?.centerId?.id
		);
		setSelectedService(filterService[0]);
	}, [userData?.centerId?.id]);

	const [hasPm, setHasPm] = useState(false);

	const [gettingPMLoading, setGettingPMLoading] = useState(false);
	const [showChangePlanRequest, setShowChangePlanRequest] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);

	const { route } = props;

	const onRedirect = (app: string) => {
		redirect(app, authUser.id, userData?.centerId?.id, authUser.stripe_id);
	};

	useEffect(() => {
		const fetchPM = async () => {
			setGettingPMLoading(true);
			const response = await pmGet(authUser.id);
			setHasPm(!isEmpty(response));
			setGettingPMLoading(false);
		};
		fetchPM();
	}, []);

	return (
		<header className="header">
			<Confirm
				isOpen={showConfirm}
				text={`Thank you! Your message has been sent, and we’ll be in touch soon.`}
				confirmText={`CLOSE`}
				onConfirm={() => setShowConfirm(false)}
			/>
			<ChangePlanRequest
				isOpen={showChangePlanRequest}
				onClose={() => setShowChangePlanRequest(false)}
				onConfirm={() => {
					setShowChangePlanRequest(false);
					setShowConfirm(true);
				}}
			/>
			<div className="headerRow">
				<div className="logo">
					<Link to="/">
						<img src={hub_logo} alt="hub logo" />
					</Link>
				</div>
				<div
					className={`headerMenu ${
						isLoggedIn ? 'visible' : 'invisible'
					}  `}
				>
					<div
						className={`menuContainer  ${
							isLoggedIn
								? selectedService?.trialEnded ||
								  (action.doAction &&
										action.reference ===
											LANDINGPAGE.new_signup)
									? 'invisible'
									: hide
									? 'invisible'
									: 'visible'
								: 'invisible'
						} `}
					>
						<ul className={`menus  `}>
							<li
								className={`menuItem ${
									route === Routes.HOMEPAGE
										? 'active'
										: 'inactive'
								}`}
							>
								{route === Routes.LANDINGPAGE ? (
									<a href="/">DASHBOARD</a>
								) : (
									<Link to="/">DASHBOARD</Link>
								)}
							</li>
							{isUserLoggedIn() &&
								authUser.user_type.toLocaleLowerCase() !==
									'user' && (
									<li
										className={` menuItem  ${
											route === Routes.SUBSCRIPTIONS
												? 'active'
												: 'inactive'
										}`}
									>
										{route === Routes.LANDINGPAGE ? (
											<a href="/subscription">
												SUBSCRIPTIONS
											</a>
										) : (
											<Link to="/subscription">
												SUBSCRIPTIONS
											</Link>
										)}
									</li>
								)}
							<li
								className={`menuItem ${
									route === Routes.USERACCOUNTS
										? 'active'
										: 'inactive'
								}`}
							>
								{route === Routes.LANDINGPAGE ? (
									<a href="/user-accounts">USERS</a>
								) : (
									<Link to="/user-accounts">USERS</Link>
								)}
							</li>
							<li className="menuItem">
								<a
									href="https://holisticmanagement.net.au/get-help/"
									target="_blank"
									rel="noreferrer"
								>
									HELP
								</a>
							</li>
							<li className="menuItem">
								<a
									href="javascript:void()"
									className={` flex justify-center items-start`}
								>
									<span className={`mr-1`}>LAUNCH</span>
									<FaCaretDown size={17} />
								</a>
								<ul className="subMenuItem">
									<li>
										<a
											className={`cursor-pointer`}
											onClick={() => {
												if (
													!selectedService?.canAccessQIP &&
													selectedService?.hasSub &&
													!hasPm
												) {
													setShowChangePlanRequest(
														true
													);
												} else if (
													selectedService?.canAccessQIP ||
													selectedService?.isOnTrial
												) {
													onRedirect('qip');
												} else if (
													!selectedService?.canAccessQIP &&
													selectedService?.redirectPaymentFailedPage
												) {
													history.push(
														'/payment/failed'
													);
												} else if (
													!selectedService?.has_qip
												) {
													history.push(`/plans`, {
														action: LANDINGPAGE.change_plan,
														centerId:
															selectedService?.has_portal
																? selectedService?.id
																: 0,
														serviceName:
															selectedService?.has_portal
																? selectedService?.name
																: '',
														productName:
															selectedService?.has_portal
																? selectedService?.has_portal &&
																  selectedService?.hasSub
																	? selectedService
																			.subscription
																			.product
																	: ''
																: '',
														interval:
															selectedService?.has_portal
																? selectedService?.has_portal &&
																  selectedService?.hasSub
																	? selectedService
																			.subscription
																			.subscription
																			.plan
																			.interval
																	: ''
																: '',
													});
												} else {
													history.push(`/plans`, {
														action: LANDINGPAGE.change_plan,
														centerId:
															selectedService?.has_portal
																? selectedService?.id
																: 0,
														serviceName:
															selectedService?.has_portal
																? selectedService?.name
																: '',
														productName:
															selectedService?.has_portal
																? selectedService?.has_portal &&
																  selectedService?.hasSub
																	? selectedService
																			?.subscription
																			?.product
																	: ''
																: '',
														interval:
															selectedService?.has_portal
																? selectedService?.has_portal &&
																  selectedService?.hasSub
																	? selectedService
																			?.subscription
																			?.subscription
																			?.plan
																			?.interval
																	: ''
																: '',
													});
												}
											}}
										>
											<span
												className={`flex justify-center items-center mr-2`}
											>
												<span className={`mr-1`}>
													HOLISTIC&nbsp;QIP{' '}
												</span>
												<FaExternalLinkSquareAlt
													size={15}
												/>
											</span>
											{selectedService?.has_qip &&
											selectedService?.hasSub &&
											selectedService?.canAccessQIP ? (
												<button className="trialBtn p-1">
													SUBSCRIBED
												</button>
											) : selectedService?.has_qip &&
											  selectedService?.hasSub &&
											  !selectedService?.canAccessQIP &&
											  selectedService?.redirectPaymentFailedPage ? (
												<button className="trialBtn p-1">
													SUBSCRIBED
												</button>
											) : !selectedService?.has_qip &&
											  selectedService?.hasSub &&
											  !selectedService?.canAccessQIP ? (
												<button className="signupBtn p-1">
													SIGN&nbsp;UP
												</button>
											) : (
												<></>
											)}

											{!selectedService?.hasSub &&
												selectedService?.has_qip && (
													<button className="trialBtn p-1">
														TRIAL
													</button>
												)}

											{selectedService?.hasSub &&
											selectedService?.isOnTrial &&
											!selectedService?.has_qip ? (
												<button className="trialBtn p-1">
													TRIAL
												</button>
											) : selectedService?.has_qip &&
											  selectedService?.hasSub &&
											  selectedService?.canAccessQIP ? (
												<button className="trialBtn p-1">
													SUBSCRIBED
												</button>
											) : selectedService?.has_qip &&
											  selectedService?.hasSub &&
											  !selectedService?.canAccessQIP &&
											  selectedService?.redirectPaymentFailedPage ? (
												<button className="trialBtn p-1">
													SUBSCRIBED
												</button>
											) : !selectedService?.has_qip &&
											  selectedService?.hasSub &&
											  !selectedService?.canAccessQIP ? (
												<button className="signupBtn p-1">
													SIGN&nbsp;UP
												</button>
											) : (
												<> </>
											)}
										</a>
									</li>
									<li>
										<a
											className={`cursor-pointer`}
											onClick={() => {
												if (
													!selectedService?.canAccessPortal &&
													selectedService?.hasSub &&
													!hasPm
												) {
													setShowChangePlanRequest(
														true
													);
												} else if (
													selectedService?.canAccessPortal ||
													selectedService?.isOnTrial
												) {
													onRedirect('portal');
												} else if (
													!selectedService?.canAccessPortal &&
													selectedService?.redirectPaymentFailedPage
												) {
													history.push(
														'/payment/failed'
													);
												} else if (
													!selectedService?.has_portal
												) {
													history.push(`/plans`, {
														action: LANDINGPAGE.change_plan,
														centerId:
															selectedService?.has_qip
																? selectedService?.id
																: 0,
														serviceName:
															selectedService?.has_qip
																? selectedService?.name
																: '',
														productName:
															selectedService?.has_qip
																? selectedService?.has_qip &&
																  selectedService?.hasSub
																	? selectedService
																			?.subscription
																			?.product
																	: ''
																: '',
														interval:
															selectedService?.has_qip
																? selectedService?.has_qip &&
																  selectedService?.hasSub
																	? selectedService
																			?.subscription
																			?.subscription
																			?.plan
																			?.interval
																	: ''
																: '',
													});
												} else {
													history.push(`/plans`, {
														action: LANDINGPAGE.change_plan,
														centerId:
															selectedService?.has_qip
																? selectedService?.id
																: 0,
														serviceName:
															selectedService?.has_qip
																? selectedService?.name
																: '',
														productName:
															selectedService?.has_qip
																? selectedService?.has_qip &&
																  selectedService?.hasSub
																	? selectedService
																			?.subscription
																			?.product
																	: ''
																: '',
														interval:
															selectedService?.has_qip
																? selectedService?.has_qip &&
																  selectedService?.hasSub
																	? selectedService
																			?.subscription
																			?.subscription
																			?.plan
																			?.interval
																	: ''
																: '',
													});
												}
											}}
										>
											<span
												className={`flex justify-center items-center mr-2`}
											>
												<span className={`mr-1`}>
													HMC&nbsp;Portal
												</span>
												<FaExternalLinkSquareAlt
													size={15}
												/>
											</span>

											{selectedService?.has_portal &&
											selectedService?.hasSub &&
											selectedService?.canAccessPortal ? (
												<button className="trialBtn p-1">
													SUBSCRIBED
												</button>
											) : selectedService?.has_portal &&
											  selectedService?.hasSub &&
											  !selectedService?.canAccessPortal &&
											  selectedService?.redirectPaymentFailedPage ? (
												<button className="trialBtn p-1">
													SUBSCRIBED
												</button>
											) : !selectedService?.has_portal &&
											  selectedService?.hasSub &&
											  !selectedService?.canAccessPortal ? (
												<button className="signupBtn p-1">
													SIGN&nbsp;UP
												</button>
											) : (
												<> </>
											)}

											{!selectedService?.hasSub &&
												selectedService?.has_portal && (
													<button className="trialBtn p-1">
														TRIAL
													</button>
												)}

											{selectedService?.hasSub &&
											selectedService?.isOnTrial &&
											!selectedService?.has_portal ? (
												<button className="trialBtn p-1">
													TRIAL
												</button>
											) : selectedService?.has_portal &&
											  selectedService?.hasSub &&
											  selectedService?.canAccessPortal ? (
												<button className="trialBtn p-1">
													SUBSCRIBED
												</button>
											) : selectedService?.has_portal &&
											  selectedService?.hasSub &&
											  !selectedService?.canAccessPortal &&
											  selectedService?.redirectPaymentFailedPage ? (
												<button className="trialBtn p-1">
													SUBSCRIBED
												</button>
											) : !selectedService?.has_portal &&
											  selectedService?.hasSub &&
											  !selectedService?.canAccessPortal ? (
												<button className="signupBtn p-1">
													SIGN&nbsp;UP
												</button>
											) : (
												<> </>
											)}
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
					<div className="headerButtons header-top-area">
						{/* Change counter id */}
						<ChangeCenter />

						{/* start a free trial */}
						<a
							href="https://dev.holisticmanagementhub.com.au/CreateAccount/CreateAccount.php"
							className="btnBlue SignIn hidden"
						>
							<svg
								fontSize="17"
								width="1em"
								height="1em"
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="rocket"
								className="svg-inline--fa fa-rocket fa-w-16"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
							>
								<path
									fill="currentColor"
									d="M505.12019,19.09375c-1.18945-5.53125-6.65819-11-12.207-12.1875C460.716,0,435.507,0,410.40747,0,307.17523,0,245.26909,55.20312,199.05238,128H94.83772c-16.34763.01562-35.55658,11.875-42.88664,26.48438L2.51562,253.29688A28.4,28.4,0,0,0,0,264a24.00867,24.00867,0,0,0,24.00582,24H127.81618l-22.47457,22.46875c-11.36521,11.36133-12.99607,32.25781,0,45.25L156.24582,406.625c11.15623,11.1875,32.15619,13.15625,45.27726,0l22.47457-22.46875V488a24.00867,24.00867,0,0,0,24.00581,24,28.55934,28.55934,0,0,0,10.707-2.51562l98.72834-49.39063c14.62888-7.29687,26.50776-26.5,26.50776-42.85937V312.79688c72.59753-46.3125,128.03493-108.40626,128.03493-211.09376C512.07526,76.5,512.07526,51.29688,505.12019,19.09375ZM384.04033,168A40,40,0,1,1,424.05,128,40.02322,40.02322,0,0,1,384.04033,168Z"
								></path>
							</svg>
							START FREE TRIAL
						</a>

						{/* Logout button */}
						<SignOut hideMenus={hideMenus} />

						<a
							href="https://dev.holisticmanagementhub.com.au/Login/Login.php"
							className="btnGreen SignIn hidden"
						>
							<svg
								fontSize="17"
								width="1em"
								height="1em"
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="sign-in-alt"
								className="svg-inline--fa fa-sign-in-alt fa-w-16"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
							>
								<path
									fill="currentColor"
									d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z"
								></path>
							</svg>
							SIGN In
						</a>
					</div>

					<div className="mobileMenu" style={{ display: 'none' }}>
						<button className="menuBars">
							<div className="bars"></div>
						</button>
						<ul className="menus">
							<li className="menuItem">
								<a href="https://dev.holisticmanagementhub.com.au/Dashboard/DashboardLoggedIn.php">
									DASHBOARD
								</a>
							</li>
							<li className="menuItem">
								<a href="https://dev.holisticmanagementhub.com.au/PaymentDetails/PaymentDetails.php">
									SUBSCRIPTIONS
								</a>
							</li>
							<li className="menuItem">
								<a href="https://dev.holisticmanagementhub.com.au/Account/UserAccounts.php">
									USERS
								</a>
							</li>
							<li className="menuItem">
								<a href="https://dev.holisticmanagementhub.com.au/Messages/Messages.php">
									MESSAGES
								</a>
								<span className="messageCount">12</span>
							</li>
							<li className="menuItem">
								<a href="https://dev.holisticmanagementhub.com.au/Help/Help.php">
									HELP
								</a>
							</li>
							<li className="menuItem hasDropdown">
								<a href="javascript:void()">
									LAUNCH
									<svg
										fontSize="17"
										width="1em"
										height="1em"
										aria-hidden="true"
										focusable="false"
										data-prefix="fas"
										data-icon="caret-down"
										className="svg-inline--fa fa-caret-down fa-w-10"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 320 512"
									>
										<path
											fill="currentColor"
											d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
										></path>
									</svg>
								</a>
								<ul
									className="subMenuItem"
									style={{ display: 'none' }}
								>
									<li>
										<a
											href="https://dev.holisticqip.com.au/"
											target="_blank"
										>
											<span>
												HOLISTIC QIP
												<svg
													fontSize="15"
													width="1em"
													height="1em"
													aria-hidden="true"
													focusable="false"
													data-prefix="fas"
													data-icon="external-link-square-alt"
													className="svg-inline--fa fa-external-link-square-alt fa-w-14"
													role="img"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 448 512"
												>
													<path
														fill="currentColor"
														d="M448 80v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48zm-88 16H248.029c-21.313 0-32.08 25.861-16.971 40.971l31.984 31.987L67.515 364.485c-4.686 4.686-4.686 12.284 0 16.971l31.029 31.029c4.687 4.686 12.285 4.686 16.971 0l195.526-195.526 31.988 31.991C358.058 263.977 384 253.425 384 231.979V120c0-13.255-10.745-24-24-24z"
													></path>
												</svg>
											</span>
											<button className="trialBtn">
												Trial
											</button>
										</a>
									</li>
									<li>
										<a
											href="https://dev.holisticportal.com.au/dashboard.php"
											target="_blank"
										>
											<span>
												Holistic portal
												<svg
													fontSize="15"
													width="1em"
													height="1em"
													aria-hidden="true"
													focusable="false"
													data-prefix="fas"
													data-icon="external-link-square-alt"
													className="svg-inline--fa fa-external-link-square-alt fa-w-14"
													role="img"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 448 512"
												>
													<path
														fill="currentColor"
														d="M448 80v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48zm-88 16H248.029c-21.313 0-32.08 25.861-16.971 40.971l31.984 31.987L67.515 364.485c-4.686 4.686-4.686 12.284 0 16.971l31.029 31.029c4.687 4.686 12.285 4.686 16.971 0l195.526-195.526 31.988 31.991C358.058 263.977 384 253.425 384 231.979V120c0-13.255-10.745-24-24-24z"
													></path>
												</svg>
											</span>
											<button className="trialBtn">
												Trial
											</button>
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
