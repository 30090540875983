import { Redirect, useParams } from "react-router";
import Details from "./Details/Details";
import Failed from "./Failed/Failed";
import SignUp from "./SignUp/SignUp";
import NewService from "./NewService/NewService";

export enum SUB_PAGE {
  signUp = "sign-up",
  details = "details",
  failed = "failed",
  newService = "new-service",
}

const Payment = () => {
  const { page } = useParams<{ page: string }>();

  switch (page) {
    case SUB_PAGE.signUp:
      return <SignUp />;
    case SUB_PAGE.details:
      return <Details />;
    case SUB_PAGE.failed:
      return <Failed />;
    case SUB_PAGE.newService:
      return <NewService />;
    default:
      return <SignUp />;
  }
};

export default Payment;
