import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import LoggedInPages from "./routes/LoggedInPages";
import LoggedOutPages from "./routes/LoggedOutPages";
import MinWidthWarning from "./pages/MinWidthWarning";
import { isUserLoggedIn, getUserLoggedIn } from "./services/auth";
import { useStoreActions, useStoreState } from "./hooks";
import { getUserInfo } from "./api/request";
import HubAppRedirect from "./routes/HubAppRedirect/HubAppRedirect";
import { isEmpty } from "lodash";
import { FaSpinner } from "react-icons/fa";
import WPHubRedirect from "./routes/WPHubRedirect";
import HubUserRedirect from "./routes/HubUserRedirect";

const App: React.FC<any> = (): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const [gettingUserInfo, setGettingUserInfo] = useState(false);

  const [isMinWidth, setIsMinWidth] = useState<boolean>(
    window.innerWidth < 746
  );
  const userInfo = useStoreState((state) => state.authStore.userInfo);
  const setUserInfo = useStoreActions(
    (actions) => actions.authStore.saveUserInfo
  );

  // const tokenStatus = useStoreState((state) => state.authStore.tokenStatus);

  // const getTokenStatus = useStoreActions(
  //   (actions) => actions.authStore.getTokenStatus
  // );

  const accessToken = isUserLoggedIn();

  const fetchServicesDetails = useStoreActions(
    (actions) => actions.userStore.fetchServicesDetails
  );

  const setShowAction = useStoreActions(
    (actions) => actions.appStore.setShowAction
  );

  useEffect(() => {
    const resize = () => setIsMinWidth(window.innerWidth < 746);
    window.addEventListener("resize", resize);
    resize();

    return () => window.removeEventListener("resize", resize);
  }, []);

  // useEffect(() => {
  //   async function fetchUserInfo() {
  //     setGettingUserInfo(true);
  //     if (accessToken && !userInfo) {
  //       const userData = await getUserInfo(accessToken);
  //       setUserInfo(userData);
  //     }
  //     setGettingUserInfo(false);
  //   }
  //   fetchUserInfo();
  // }, [accessToken, setUserInfo, userInfo]);

  useEffect(() => {
    let authUser = getUserLoggedIn();

    async function getServiceDetails() {
      setLoading(true);
      if (accessToken && !isEmpty(authUser)) {
        setUserInfo(authUser);
        await fetchServicesDetails(authUser.id);
      } else {
        localStorage.clear();
      }
      setLoading(false);
    }
    getServiceDetails();
  }, [accessToken, fetchServicesDetails]);

  useEffect(() => {
    setShowAction({
      doAction: false,
      reference: "",
    });
  }, []);

  return (
    <>
      {loading ? (
        <div
          className={`w-full h-screen flex flex-col items-center justify-center`}
        >
          <FaSpinner className={`animate-spin text-qipGreen text-6xl`} />
          <span>Please wait...</span>
        </div>
      ) : (
        <Router basename={process.env.BASENAME}>
          {isMinWidth ? (
            <MinWidthWarning />
          ) : (
            <Switch>
              <Route
                path={`/view-hub-admin-service`}
                render={() => {
                  return <HubUserRedirect />;
                }}
              />
              <Route
                path={`/view-hub-user-service`}
                render={() => {
                  return <HubUserRedirect />;
                }}
              />
              <Route
                path={`/wp-hub-redirect`}
                render={() => {
                  return <WPHubRedirect />;
                }}
              />

              <Route
                path={`/app-redirect-hub`}
                render={() => {
                  return <HubAppRedirect />;
                }}
              />
              <Route
                path={`/`}
                render={() => {
                  return accessToken ? <LoggedInPages /> : <LoggedOutPages />;
                }}
              ></Route>
            </Switch>
          )}
        </Router>
      )}
    </>
  );
};

export default App;
