import create_account_banner from "../../static/images/create_account_banner.jpg";
import PricingTable from "../Plans/PricingTable";
import styles from "./index.module.css";
import { useStoreActions, useStoreState } from "../../hooks";
import { useHistory } from "react-router-dom";
import { LANDINGPAGE } from "../../enum/action";

const NO_ACTIVE_PLAN = {
  TITLE: "Your trial with Holistic Management has ended",
};

const HAS_PLAN = {
  TITLE: "Choose your Holistic Management products",
};

// To continue to enjoy all the benefits of Holistic QIP and Holistic Management Poral after your trial period ends, signup to one of our plans.

//

const Trial = () => {
  const history = useHistory();

  const services = useStoreState((state) => state.userStore.servicesDetails);

  const trialEnded = services?.length === 1 && services[0].trialEnded;

  return (
    <>
      <div
        className={`${styles.pageBanner} pageBanner`}
        style={{ backgroundImage: `url('${create_account_banner}')` }}
      >
        <div className={`${styles.container}`}>
          <div className={`${styles.pageBannerContent}`}>
            <h2>{trialEnded ? NO_ACTIVE_PLAN.TITLE : HAS_PLAN.TITLE}</h2>
            <p>
              To continue to enjoy all the benefits of Holistic QIP and Holistic
              Management Portal after your trial period ends, signup to one of
              our plans.
            </p>
          </div>
        </div>
      </div>
      <div className="my-2 mx-10 mb-20">
        <div
          className={`${styles.description} flex items-center flex-col my-10`}
        >
          <p>Our pricing</p>
          <h2 className="p-5 text-center">
            Find a pricing plan that is right for you
          </h2>
        </div>
        <PricingTable
          noActivePlan={trialEnded}
          intervalForChangePlan={""}
          banner="*Benefit from a discount with an annual plan"
          onSignUp={(details) => {
            console.log({ details });
            history.push(`/payment/details`, {
              ...details,
              action: LANDINGPAGE.trial_ended,
              trialEnded,
            });
          }}
          showDiscountInfo
        />
      </div>
    </>
  );
};

export default Trial;
