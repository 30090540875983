import { useCallback, useState } from "react";
import styles from "./Failed.module.css";
import Card from "../../../components/Form/Card";
import PageBanner from "../PageBanner/PageBanner";
import CardIcons from "../../Subscription/CardIcons";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { add, paymentMethodApi } from "../../../api/paymentMethod";
import { useMutation, useQueryClient } from "react-query";
import { useStoreState } from "../../../hooks";
import useFetchPaymentMethods from "../../../hooks/useFetchPaymentMethods";
import { FaRocket, FaTimes } from "react-icons/fa";
import BtnLoading from "../../../components/Form/BtnLoading";
import Confirm from "../../../components/Popups/Confirm";
import Spinner from "../../../components/Spinner";

const Failed = () => {
  const [showErrors, setShowErrors] = useState(false);
  const [cardName, setCardName] = useState("");

  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const [toDelete, setToDelete] = useState<string>();
  const [showSuccessAdded, setShowSuccessAdded] = useState(false);

  const [loadingAddPayment, setLoadingAddPayment] = useState(false);
  const userInfo = useStoreState((state) => state.authStore.userInfo);

  const { data: paymentMethods, isFetching } = useFetchPaymentMethods();
  const { isLoading: isLoadingDelete, mutate: removePM } = useMutation(
    paymentMethodApi.remove,
    {
      onSuccess: () => {
        setToDelete(undefined);
        queryClient.invalidateQueries("paymentMethods");
      },
    }
  );

  const onAddPaymentMethod = useCallback(async () => {
    if (!stripe || !elements) return;

    const card = elements.getElement(CardNumberElement);
    if (!card) return;

    setShowErrors(false);
    if (!cardName) {
      setShowErrors(true);
      return;
    }

    setLoadingAddPayment(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        name: cardName,
      },
    });

    error && setShowErrors(true);

    if (paymentMethod && userInfo) {
      const success = await add(userInfo?.id, paymentMethod.id);
      setLoadingAddPayment(false);
      queryClient.invalidateQueries("paymentMethods");

      if (success) {
        success && setShowSuccessAdded(true);
        setCardName("");
        card.clear();
        elements.getElement(CardExpiryElement)?.clear();
        elements.getElement(CardCvcElement)?.clear();
      }
    } else {
      setLoadingAddPayment(false);
    }
  }, [cardName, elements, queryClient, stripe, userInfo]);

  return (
    <>
      <Confirm
        text={`Are you sure you want to remove this payment method?`}
        isOpen={toDelete !== undefined}
        confirmText={isLoadingDelete ? undefined : "UNLINK"}
        declineText="CANCEL"
        onConfirm={() => toDelete && removePM(toDelete)}
        onClose={() => setToDelete(undefined)}
        onDecline={isLoadingDelete ? undefined : () => setToDelete(undefined)}
      >
        {isLoadingDelete && (
          <div>
            <Spinner text="Please wait..." />
          </div>
        )}
      </Confirm>
      <Confirm
        text={`New payment method is now added to Holistic Hub`}
        isOpen={showSuccessAdded}
        confirmText="OK"
        onConfirm={() => setShowSuccessAdded(false)}
        onClose={() => setShowSuccessAdded(false)}
      />
      <PageBanner title="Payment failed" />
      <div className={styles.container}>
        <div
          className={`${styles.mainContent} grid md:grid-cols-1 lg:grid-cols-2 gap-10`}
        >
          <div>
            <h2>Payment failed</h2>
            <p className={styles.description}>
              We were unable to take payment for your Holistic Management
              subscription. Please review & update your payment method so that
              we can help you regain access to your products.
            </p>
          </div>
          <div>
            <h2>Subscription by Credit Card (Stripe)</h2>
            <div className={`${styles.table} table w-full mt-10`}>
              <div className="table-row-group">
                <div className={`${styles.row} table-row`}>
                  <div className="table-cell">
                    <p>Method</p>
                  </div>
                  <div className="table-cell">
                    <p>Expires</p>
                  </div>
                  <div className="table-cell">
                    <p>Delete</p>
                  </div>
                </div>

                {paymentMethods &&
                  paymentMethods.map((pm) => {
                    return (
                      <div className={`${styles.row} table-row`}>
                        <div className="table-cell">
                          <p className="font-light">{`${
                            pm.card.brand.charAt(0).toUpperCase() +
                            pm.card.brand.slice(1)
                          } ending ${pm.card.last4}`}</p>
                        </div>
                        <div className="table-cell">
                          <p className="font-light">{`${pm.card.exp_month}/${pm.card.exp_year}`}</p>
                        </div>
                        <div className="table-cell">
                          {paymentMethods.length > 1 && (
                            <button
                              className={styles.removeBtn}
                              onClick={() => setToDelete(pm.id)}
                            >
                              <FaTimes size={19} />
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={`${styles.method} mt-8`}>
              <h3>Update payment method</h3>
              <p className="flex items-center font-light">
                Update your credit card via Stripe.
                <span className="flex gap-x-1 ml-2.5">
                  <CardIcons />
                </span>
              </p>
            </div>
            <div className="mt-8">
              <Card
                cardName={cardName}
                setCardName={setCardName}
                showErrors={showErrors}
              />
            </div>
            <div className="mt-5">
              <button
                className={styles.payBtn}
                onClick={onAddPaymentMethod}
                disabled={loadingAddPayment}
              >
                {loadingAddPayment ? (
                  <BtnLoading />
                ) : (
                  <>UPDATE PAYMENT DETAILS</>
                )}
              </button>
            </div>
            <div className={`${styles.failedAlert} mt-10 p-5`}>
              <span className={`font-medium`}>
                {" "}
                We were unable to take payment for your Holistic Management
                subscription.
              </span>{" "}
              Please review & update your payment method so that we can help you
              regain access to your products.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Failed;
