import { useState } from "react";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../hooks";
import AddServiceRequest from "../../components/Popups/AddServiceRequest/AddServiceRequest";
import Confirm from "../../components/Popups/Confirm";
import { LANDINGPAGE } from "../../enum/action";

export default function AddService({ hasPM = false }) {
  const history = useHistory();

  const [showAddServiceRequest, setShowAddServiceRequest] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const services = useStoreState((state) => state.userStore.servicesDetails);

  return (
    <div className="addSubscription">
      <Confirm
        isOpen={showConfirm}
        text={`Thank you! Your message has been sent, and we’ll be in touch soon.`}
        confirmText={`CLOSE`}
        onConfirm={() => setShowConfirm(false)}
      />
      <AddServiceRequest
        isOpen={showAddServiceRequest}
        onClose={() => setShowAddServiceRequest(false)}
        onConfirm={() => {
          setShowAddServiceRequest(false);
          setShowConfirm(true);
        }}
      />
      <div className="addSubscriptionContent">
        <h2>Add a new service to Hub</h2>
        <p>
          Extend your subscription by adding another of your early childcare
          services to your current Holistic Management subscription.
        </p>
      </div>
      <div className="addSubscriptionButton">
        <button
          onClick={() => {
            if (
              services?.some(
                (service) =>
                  service.subStatus === "active" ||
                  service.subStatus === "trialing"
              ) &&
              hasPM
            ) {
              history.push(`/plans`, {
                action: LANDINGPAGE.new_service,
              });
            }

            if (!hasPM) {
              setShowAddServiceRequest(true);
            }
          }}
        >
          <FaArrowAltCircleDown size={19} />
          ADD A NEW SERVICE TO HUB
        </button>
      </div>
    </div>
  );
}
