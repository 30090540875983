import { FaCcAmex, FaCcJcb, FaCcMastercard, FaCcVisa } from "react-icons/fa";

const CardIcons = () => {
  return (
    <>
      <FaCcVisa size={30} color="#003E94" />
      <FaCcMastercard size={30} color="#FD0E26" />
      <FaCcAmex size={30} color="#1C4AD4" />
      <FaCcJcb size={30} color="#004695" />
    </>
  );
};

export default CardIcons;
