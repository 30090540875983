import React from "react";
import { Button } from "semantic-ui-react";
import BaseModal from "../../BaseModal";
import styles from "./IncorrectPassword.module.css";

type IncorrectPasswordProps = {
  isOpen: boolean;
  onTryAgain?: () => void;
  onGetNewPass?: () => void;
  onClose?: () => void;
};

export default function IncorrectPassword(props: IncorrectPasswordProps) {
  return (
    <BaseModal isOpen={props.isOpen} onClose={props.onClose}>
      <div className="flex flex-col items-center mb-8 mx-8 mt-4">
        <p className={`${styles.textPrompt} mb-5`}>
          The password you have provided is not correct
        </p>
        <div className={styles.actionBtns}>
          <Button
            className={`${styles.tryAgain} mr-3`}
            onClick={props.onTryAgain}
          >
            TRY SIGNING IN AGAIN
          </Button>
          <Button className={styles.getNew} onClick={props.onGetNewPass}>
            GET NEW PASSWORD
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}
