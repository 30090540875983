import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";

const Payment = lazy(() => import("../../pages/Payment"));

interface PaymentProps {
  route: typeof Routes.PAYMENT;
  path: string;
  exact: boolean;
}

const PaymentRoute: React.FC<PaymentProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute path={path} route={route} exact={exact} Component={Payment} />
  );
};

export default PaymentRoute;
