import { FaSignInAlt } from 'react-icons/fa';
import {
	isUserLoggedIn,
	userLogout,
} from '../../services/auth';
import { useStoreState } from '../../hooks';

const SignOut = ({hideMenus}:{hideMenus:any}) => {
    const isLoggedIn = isUserLoggedIn();
    const services = useStoreState(state => state.userStore.servicesDetails);
    const hide = hideMenus && isLoggedIn;

    return(
        <a
            className={`btnGreen SignOut ${
                !isLoggedIn ||
                (services?.length === 0 && services[0].trialEnded)
                    ? 'hidden' 
                    : hide 
                    ? '' 
                    : ''
            }`}
            onClick={e => {
                e.preventDefault();
                userLogout(window.location.reload());
            }}
        >
            <FaSignInAlt size={17} />
            SIGN OUT
        </a>
    );

}

export default SignOut;