type RequiredProps = { customText?: string; className?: string };

const Required = (props: RequiredProps) => {
  return (
    <p className={`${props.className || ""} text-red-400 italic py-1 px-1 h-4`}>
      {props.customText || "This field is required."}
    </p>
  );
};

export default Required;
